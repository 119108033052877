import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModelTableCheckedItem, ModelTableHoverDetail, ModelTableHoverDetailItem, ModelTableHoverDetailItemWithIndex, ModelTableOptionSelected, ModelTableOptionSelectedDelete, ModelTableOptionSelectedPause, ModelTableOptionSelectedResume, ModelTablePendingRequest } from '../models/models';

@Component({
  selector: 'app-basic-table',
  host: { class: "tdw-table" },
  encapsulation: ViewEncapsulation.None,
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent {
  _mDirectEdit: boolean = false;
  _isAuditLog: boolean = false;
  _mToggleStatus: boolean = false;
  toggleStatus: boolean = true;
  _mIsView: boolean = false;
  _mIsReply: boolean = false;
  _mPause: boolean = false;
  _mResume: boolean = false;
  _mDelete: boolean = false;
  _mIsCheckable: boolean = false;
  _mIsAllChecked: boolean = false;
  mIsOptionSelected: boolean;
  _mDetails: ModelTableHoverDetailItem[];
  _mTestMenuTrigger: MatMenuTrigger;
  mCurrentDetailRowIndex: number = -1;
  mSelectedOption: string;
  _isDownload: boolean = false;
  _mOptions: string[];
  _mIsPauseData: any[] = [];
  _mIsResumeData: any[] = [];
  _mIsDeleteData: any[] = [];
  _mIsCheckedList: boolean[] = [];
  _mDataRaw: any[];
  _misAdhocBooking: boolean = false;
  _mAction: boolean = false;
  _mSubscribeCancel: boolean = false;
  _redBtn:boolean =false;

  @Input() columnHeaders: any[];
  @Input() tableData: any[];

  @Input("action") set action(options: boolean) {
    this._mAction = options;
  }

  @Input("subscribeCancel") set cancel(options: boolean) {
    this._mSubscribeCancel = options;
  }

  @Input("toggleStatus") set toggleStatusM(status: boolean) {
    this._mToggleStatus = status;
  }

  @Input("subcribeEdit") set edit(status: boolean) {
    this._mDirectEdit = status;
  }

  @Input("isAuditLog") set isAuditLog(status: boolean) {
    this._isAuditLog = status;
  }

  @Input("isReply") set isReply(status: boolean) {
    this._mIsReply = status;
  }

  @Input("itemOptions") set options(options: string[]) {
    this._mOptions = options;
  }

  @Input() public isEditIcon: boolean;

  @Input("isDownload") set isDownload(status: boolean) {
    this._isDownload = status;
  }

  @Input("isAdhocBooking") set isAdhocBooking(options: boolean) {
    this._misAdhocBooking = options;
  }

  @Input("redBtn") set Button(status: boolean) {
    this._redBtn = status;
  }

  @Output("onSubscribeCancel")
  mOnSubscribeCancel = new EventEmitter<ModelTableOptionSelected>();

  @Output("onStatusChanged")
  mOnStatusChanged = new EventEmitter<ModelTableOptionSelected>();

  @Output("onEditIconClicked")
  mOnEditIconClicked = new EventEmitter<ModelTableOptionSelected>();

  @Output("onRedBtnClicked")
  mredBtn = new EventEmitter<ModelTableOptionSelected>();

  @Output("onAuditLog")
  mOnAuditLog = new EventEmitter<ModelTableOptionSelected>();

  @Output("pause")
  mPause = new EventEmitter<ModelTableOptionSelectedPause>();

  @Output("resume")
  mResume = new EventEmitter<ModelTableOptionSelectedResume>();

  @Output("delete")
  mDelete = new EventEmitter<ModelTableOptionSelectedDelete>();

  @Output("onCheckChangeAll")
  mOnCheckChangeAll = new EventEmitter<boolean>();

  @Output("onCheckChangeItem")
  mOnCheckChangeItem = new EventEmitter<ModelTableCheckedItem>();

  @Output("onItemOptionSelected")
  mOnItemOptionSelected = new EventEmitter<ModelTableOptionSelected>();

  @Output("onDetailItemSelected")
  mOnDetailItemSelected = new EventEmitter<ModelTableHoverDetailItemWithIndex>();

  @Output("onReplyIconClicked")
  mOnReplyLog = new EventEmitter<ModelTableOptionSelected>();

  @Output("onDownload")
  mOnDownload = new EventEmitter<ModelTableOptionSelected>();

  @Output("onPendingStatusRequest")
  mOnPendingStatusRequest = new EventEmitter<ModelTablePendingRequest>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor() { }

  ngOnInit(): void {
  }

  statusToggle(data) {
    let index = this.tableData.indexOf(data);
    let option = new ModelTableOptionSelected(index, data);
    this.mOnStatusChanged.emit(option);
  }

  onEditIconClicked(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, item);
    this.mOnEditIconClicked.emit(option);
  }

  onAuditLogClicked(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, item);
    this.mOnAuditLog.emit(option);
  }

  onRedBtnClicked(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, item);
    this.mredBtn.emit(option);
  }

  onSubscribeCancel(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, this.mSelectedOption);
    this.mOnSubscribeCancel.emit(option);
  }

  @Input() set isPause(val: boolean) {
    this._mPause = val;
    if (this._mPause && this._mDataRaw) {
      this._mIsPauseData = [];
      this._mDataRaw.forEach(element => {
        this._mIsPauseData.push(element);
      });
    }
  }

  @Input() set isResume(val: boolean) {
    this._mResume = val;
    if (this._mResume && this._mDataRaw) {
      this._mIsResumeData = [];
      this._mDataRaw.forEach(element => {
        this._mIsResumeData.push(element);
      });
    }
  }

  @Input() set isDelete(val: boolean) {
    this._mDelete = val;
    if (this._mDelete && this._mDataRaw) {
      this._mIsDeleteData = [];
      this._mDataRaw.forEach(element => {
        this._mIsDeleteData.push(element);
      });
    }
  }

  @Input("data") set data(data: any[]) {
    if (!data) {
      return;
    }
    if (this._mIsCheckable) {
      if (data === this._mDataRaw) {
        //return;
      } else {
        this._mIsCheckedList = [];
      }
      data.forEach(element => {
        this._mIsCheckedList.push(false);
      });
    }
    this._mDataRaw = data;
  }

  @Input("isCheckable") set isCheckable(val: boolean) {
    this._mIsCheckable = val;
    if (this._mIsCheckable && this._mDataRaw) {
      this._mIsCheckedList = [];
      this._mDataRaw.forEach(element => {
        this._mIsCheckedList.push(false);
      });
    }
  }

  setItemChecked(index: number, isChecked: boolean) {
    if (this._mIsCheckable) {
      this._mIsCheckedList[index] = isChecked;
      if (isChecked === false && this._mIsAllChecked == true) {
        this._mIsAllChecked = false;
      }
    }
  }

  getIndex(item) {
    let index = this.tableData.indexOf(item);
    return index;
  }

  onOptionMenuClosed(item: any) {
    let index = this.tableData.indexOf(item);
    if (this.mIsOptionSelected) {
      let option = new ModelTableOptionSelected(index, item);
      this.mIsOptionSelected = false;
      this.mOnItemOptionSelected.emit(option);
    }
  }

  onUpdateCheckAll($event) {
    for (let i = 0; i < this._mIsCheckedList.length; i++) {
      this._mIsCheckedList[i] = $event.checked;
    }
    this._mIsAllChecked = $event.checked;
    this.mOnCheckChangeAll.emit($event.checked);
  }

  onUpdateCheckItem($event, item: any) {
    let index = this.tableData.indexOf(item);
    if (index < 0) {
      return;
    }
    this._mIsCheckedList[index] = $event.checked;
    this.mOnCheckChangeItem.emit(new ModelTableCheckedItem(index, $event.checked, this._mDataRaw[index]));
  }

  openDetailMenu(menuTrigger: MatMenuTrigger, item: any, column) {
    let rowIndex = this.tableData.indexOf(item);
    let details: ModelTableHoverDetail = this.tableData[rowIndex][column.key];
    if (details.items && details.items.length > 0) {
      this._mDetails = details.items;
      this._mTestMenuTrigger = menuTrigger;
      this.mCurrentDetailRowIndex = rowIndex;
      this._mTestMenuTrigger.openMenu();
    }
  }

  _closeDetailMenu() {
    if (this._mTestMenuTrigger) {
      this._mTestMenuTrigger.closeMenu();
      this.mCurrentDetailRowIndex = -1;
      this._mTestMenuTrigger = null;
    }
  }

  onDetailMenuSelected(detail: ModelTableHoverDetailItem) {
    if (this._mTestMenuTrigger) {
      this._mTestMenuTrigger = null;
      this.mOnDetailItemSelected.emit(new ModelTableHoverDetailItemWithIndex(detail, this.mCurrentDetailRowIndex));
      this.mCurrentDetailRowIndex = -1;
    }
  }

  onOptionMenuSelected(option: string) {
    if (option) this.mSelectedOption = option;
    this.mIsOptionSelected = true;
  }

  onPause(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelectedPause(index, item, this.tableData[index]);
    this.mPause.emit(option);
  }

  onResume($event, item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelectedResume(index, this._mDataRaw[index]);
    this.mResume.emit(option);
  }

  onDelete(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelectedDelete(index, this.tableData[index]);
    this.mDelete.emit(option);
  }

  onReplyIconClicked(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, this.mSelectedOption);
    this.mOnReplyLog.emit(option);
  }

  onDownloadIconClicked(item: any) {
    let index = this.tableData.indexOf(item);
    let option = new ModelTableOptionSelected(index, item);
    this.mOnDownload.emit(option);
  }

  onPendingStatusRequest(item: any, status: any) {
    let index: number = this.tableData.indexOf(item);
    let option = new ModelTablePendingRequest(index, status);
    this.mOnPendingStatusRequest.emit(option);
  }

  getTitle(key, item) {
    if (key == 'action') {
      return '';
    } else if (key == 'requesterRoles') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'approverRoles') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'recipientRoles') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'bcc_Roles') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'ccRoles') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'menus') {
      if (item[key].items.length > 0) {
        return item[key].items.map(ele => {
          return ele.value
        }).join(', ');
      } else {
        return '';
      }
    } else if (key == 'callRecordingUrl') {
      return 'Call Recording URL';
    } else {
      return item[key];
    }
  }


}
