import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-nearby-veh-trip-info',
  templateUrl: './nearby-veh-trip-info.component.html',
  styleUrls: ['./nearby-veh-trip-info.component.scss']
})
export class NearbyVehTripInfoComponent {

  tripListData: any[] = [];
  employeeStats: any[] = [];

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<NearbyVehTripInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    if (this.data) {
      this.showSingleRunningTrip();
    }
  }

  showSingleRunningTrip() {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.showSingleRunningTrip + this.data.tripId, '', success => {
      if (success.status == 200) {
        this.ngxLoader.stop();
        this.tripListData = success.body;
        if (success.body[0].tripDetails.tripCode.indexOf('SPL') == -1) {
          this.calculateEmployees(success.body);
        }
      } else {
        this.ngxLoader.start();
        this.notifyService.showInfo("No trips found", null);
      }
    },
      error => {
      }
    );
  }

  calculateEmployees(trips) {
    this.employeeStats = [];
    trips.forEach((trip, t) => {
      this.employeeStats.push({
        femaleEmployees: 0,
        maleEmployees: 0,
        totalEmployees: 0,
        femaleShowStats: 0,
        maleShowStats: 0,
        femaleNoShowStats: 0,
        maleNoShowStats: 0,
        currentLocationPin: 0
      });
      this.employeeStats[t].totalEmployees = trip.tripDetails.tripUsers.length;
      trip.tripDetails.tripUsers.forEach((emp, e) => {
        emp.showStatus === "SHOW"
          ? (this.employeeStats[t].currentLocationPin = e + 1)
          : "";
        if (emp.tripUser.userInfoDetails.gender === "Female") {
          this.employeeStats[t].femaleEmployees++;
          if (emp.showStatus === "SHOW") {
            this.employeeStats[t].femaleShowStats++;
          }
          if (emp.showStatus === "NO SHOW") {
            this.employeeStats[t].femaleNoShowStats++;
          }
        } else {
          this.employeeStats[t].maleEmployees++;
          if (emp.showStatus === "SHOW") {
            this.employeeStats[t].maleShowStats++;
          }
          if (emp.showStatus === "NO SHOW") {
            this.employeeStats[t].maleNoShowStats++;
          }
        }
      });
    });
  }

  doIVRCall(tripUserId, tripId, empType) {
    this.ngxLoader.start();
    if (empType == 'driver') {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&driverId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    } else if (empType == 'escort') {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&escortId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    } else {
      this.apiService.doPostRequestWithResponse(ApiConstants.ivrEmpCall + tripId + '&tripUserId=' + tripUserId, '', (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
        } else {
          this.ngxLoader.stop();
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    }
  }

}


