<div class="mat-modal-dialog trip-info-modal">
    <h2 mat-dialog-title>
        <div>
            <span class="material-icons-outlined"
                style="color: #FF0000; position: relative; top: 7px; margin-right: 10px; font-size: 20px;">
                info</span> <span
                style="color: #495057; font-size: 14px; font-weight: 500; font-family: 'poppins'; position: relative; top: 2px;">Nearby
                Vehicle Info.</span>
        </div>

        <span class="material-icons-outlined" style="color: #FF0000; font-size: 20px; cursor: pointer;"
            (click)="dialogRef.close()">close</span>
    </h2>

    <mat-dialog-content>
        <div class="row m-0">
            <div class="col-sm-12 col-12 p-0" *ngFor="let trip of tripListData; let t=index;">
                <div class="row mt-1">
                    <div class="col-sm-12 col-md-12" style="display: flex; justify-content: space-between;">
                        <div style="font-size: 13px; font-family: 'Poppins'; font-weight: 500; color: #495057;">
                            Vendor Name : {{trip.vendorMaster?.name}} </div>
                        <div style="font-size: 13px; font-family: 'Poppins'; font-weight: 500; color: #495057;"> Vehicle
                            Type : {{trip.vehicleType?.vehicleType}} </div>
                        <div style="font-size: 13px; font-family: 'Poppins'; font-weight: 500; color: #495057;"> Vehicle
                            No : {{trip.vehicle?.registrationNo}} </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="card m-0 nearbyveh-info">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 d-flex trip-div">
                                        <div class="trip-detail-div-panic">
                                            <div class="trip-detail-heading-panic">Driver Details
                                            </div>

                                            <div class="driver-detail-trip-panic pe-1 mb-2">
                                                <span class="material-icons-outlined">directions_car_filled</span>
                                                <span>{{ trip.driver?.name ? trip.driver?.name :
                                                    'Not
                                                    assigned' }}</span>
                                            </div>

                                            <div class="driver-detail-trip-panic pe-1">
                                                <!-- <span class="material-icons-outlined">call</span> -->
                                                <button class="btn trip-sos-ivrcall"
                                                    (click)="doIVRCall(trip.driver.id, trip.tripDetails.tripId, 'driver')"
                                                    [disabled]="!data.ivrSetupFlag || !trip.driver?.contactNumber">
                                                    <span
                                                        style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                                <span
                                                    style="position: relative; left: 5px;">{{trip.driver?.contactNumber
                                                    ?
                                                    trip.driver?.contactNumber : 'Not
                                                    assigned'}}</span>
                                            </div>
                                        </div>
                                        <div class="trip-detail-div-panic">
                                            <div class="trip-detail-heading-panic">Escort Details
                                            </div>

                                            <div class="driver-detail-trip-panic pe-1 mb-2">
                                                <span class="material-icons-outlined">person</span>
                                                <span>{{trip.escort?.name ? trip.escort?.name : 'Not
                                                    assigned'}}</span>
                                            </div>

                                            <div class="driver-detail-trip-panic pe-1">
                                                <!-- <span class="material-icons-outlined">call</span> -->
                                                <button class="btn trip-sos-ivrcall"
                                                    (click)="doIVRCall(trip.escort.escortId, trip.tripDetails.tripId, 'escort')"
                                                    [disabled]="!data.ivrSetupFlag || !trip.escort?.contactNumber">
                                                    <span
                                                        style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                                <span style="position: relative; left: 5px;">{{
                                                    trip.escort?.contactNumber ?
                                                    trip.escort?.contactNumber : 'Not assigned'
                                                    }}</span>
                                            </div>
                                        </div>
                                        <div class="trip-detail-div-panic"
                                            *ngIf="trip.tripDetails.tripCode.indexOf('SPL') !== -1">
                                            Special
                                            Booking Trip</div>
                                        <div class="trip-detail-div-panic"
                                            *ngIf="trip.tripDetails.tripCode.indexOf('SPL') == -1">
                                            <table class="table customTableTripInfo">
                                                <thead>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th>{{employeeStats[t]?.totalEmployees}}E
                                                        </th>
                                                        <th>{{employeeStats[t]?.maleShowStats +
                                                            employeeStats[t]?.femaleShowStats}}S
                                                        </th>
                                                        <th>{{employeeStats[t]?.maleNoShowStats +
                                                            employeeStats[t]?.femaleNoShowStats}}NS
                                                        </th>
                                                        <th>0C</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td><img style="width: 15px;"
                                                                src="./assets/images/livetracking/male.png">
                                                        </td>
                                                        <td>{{employeeStats[t]?.maleEmployees}}E
                                                        </td>
                                                        <td>{{employeeStats[t]?.maleShowStats}}S
                                                        </td>
                                                        <td>{{employeeStats[t]?.maleNoShowStats}}NS
                                                        </td>
                                                        <td>0C</td>
                                                    </tr>
                                                    <tr>
                                                        <td><img style="width: 15px;"
                                                                src="./assets/images/livetracking/female.png">
                                                        </td>
                                                        <td>{{employeeStats[t]?.femaleEmployees}}E
                                                        </td>
                                                        <td>{{employeeStats[t]?.femaleShowStats}}S
                                                        </td>
                                                        <td>{{employeeStats[t]?.femaleNoShowStats}}NS
                                                        </td>
                                                        <td>0C</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section id="nearbyvehinfo">
                            <div class="nearbyvehinfo-line"></div>
                            <div class="nearbyvehinfo-content">
                                <div class="nearbyvehinfo-article">
                                    <div class="content-right-container d-flex">
                                        <div class="start">{{trip.startDateTime ?
                                            trip.startDateTime?.split(' ')[1].slice(0,5) : 'NA'}}
                                        </div>
                                        <div class="start">- Start Time </div>
                                    </div>
                                    <div class="meta-date start_end_time"
                                        [style.background]="(employeeStats[t]?.maleShowStats+employeeStats[t]?.femaleShowStats > 0) ? '#43DF94' : '#e4e4e4'">
                                    </div>
                                </div>
                                <div class="nearbyvehinfo-article"
                                    *ngFor="let user of trip.tripDetails.tripUsers; let u=index;">
                                    <div class="content-left-container d-flex" style="align-items: center;">
                                        <div class="col-md-2"><img style="width: 15px;"
                                                [src]="user.tripUser.userInfoDetails.gender=='Female' ?
                                            'assets/images/livetracking/female.png' : 'assets/images/livetracking/male.png'"></div>
                                        <div class="col-md-8"
                                            style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                            {{user.tripUser.userInfoDetails.employeeId}} -
                                            {{user.tripUser.userInfoDetails.displayName |
                                            slice:0:13}}</div>

                                        <div class="col-md-2"
                                            style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                            {{user.pickupDropTime
                                            ? (user.pickupDropTime | slice:0:5) : 'NA'}}</div>
                                    </div>
                                    <div class="content-right-container d-flex" style="align-items: center;">
                                        <div class="col-md-8"
                                            style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                            <span *ngIf="user.showStatus!='SHOW'">{{user.showStatus}}</span>
                                            <span *ngIf="user.showStatus=='SHOW'">IN
                                                {{user.boardedDateTime?.split(' ')[1] | slice:0:5}}
                                                - OUT
                                                <span>{{user.arrivalDateTime ?
                                                    (user.arrivalDateTime?.split(' ')[1] |
                                                    slice:0:5) :
                                                    'NA'}}</span> </span>
                                        </div>
                                        <div class="col-md-2"><img style="width: 15px;"
                                                [src]="user.showStatus=='SHOW' ? 'assets/images/Group.png' : 'assets/images/livetracking/into_mark.png'">
                                        </div>

                                        <div class="col-md-1">
                                            <button class="btn trip-sos-ivrcall"
                                                (click)="doIVRCall(user.tripUser.id, trip.tripDetails.tripId, 'employee')"
                                                [disabled]="!data.ivrSetupFlag || user.arrivalDateTime !== null">
                                                <span
                                                    style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                        </div>
                                    </div>
                                    <div class="meta-date"
                                        [style.background]="(user.showStatus=='SHOW') ? '#43DF94' : '#e4e4e4'">
                                    </div>
                                </div>

                                <div class="nearbyvehinfo-article">
                                    <div class="content-right-container d-flex" style="align-items: center;">
                                        <div class="col-md-2 start">{{trip.stopDateTime ?
                                            (trip.stopDateTime?.split(' ')[1] | slice:0:5) : 'NA'}}
                                        </div>
                                        <div class="start">- End Time </div>
                                    </div>
                                    <img style="width: 15px;" class="meta-date1 start_end_time"
                                        src="./assets/images/loc.svg">
                                </div>
                            </div>
                        </section>

                        <div class="row mt-5" *ngIf="trip.tripDetails.tripCode.indexOf('SPL') == -1">
                            <div class="col-sm-4 col-md-4">
                                <div class="trip_lable">Estimated</div>
                                <div class="trip_detail">{{trip?.etaMin}} min/<span>{{trip?.etaKm |
                                        number :
                                        '1.2-2'}} Km</span> </div>
                            </div>

                            <div class="col-sm-4 col-md-4">
                                <div class="trip_lable">Consumed</div>
                                <div class="trip_detail">{{trip?.consumedMin}}
                                    min/<span>{{trip?.consumedKm |
                                        number : '1.2-2'}} Km</span></div>
                            </div>

                            <div class="col-sm-4 col-md-4">
                                <div class="trip_lable">Late</div>
                                <div class="trip_detail">{{trip?.lateTime}} min<span></span> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>