import { get } from "lodash";

export const ApiConstants = {
    login: 'login',
    getLoginOtp: 'newuser/request-otp',
    forgotPassword: 'newuser/v3/forgotpassword?emailid=',
    updateUser: 'newuser/updateuser',
    getCurrentUser: 'user/currentuserinfo',
    getUserById: 'newuser/getbyuserinfobyemail?emailid=',
    userRegistration: 'newuser/userregistration',
    getprofilePhoto: 'userinfo/getuserprofileurlbyuserid?userId=',
    renewJWT: 'refreshtokenweb/renewjwt',
    addTempUser: 'newuser/addtempuser',
    getTempUser: 'newuser/gettempuserbyemail?emailid=',
    getsitesbyuserid: 'site/getsitesbyuserid',
    safeDropSummary: 'safedrop/safedropsummary',
    getlocationlist: 'newuser/getlocationlist',
    getsiteslist: 'newuser/getsiteslist?locationId=',  //  its a open api, it should only be used for forgot password like cases where we will not be logging in
    getSubscriptionSetupAuditById: 'audit/getsubscriptionsetupaudit',
    getAreaslist: 'newuser/getareaslist?locationId=',
    getLandmarkslist: 'newuser/getlandmarkslist?areaId=',
    getRolePermissions: 'role/getrolepermissions?roleId=',
    getRolePermission: 'role/getrolepermissions',
    getDelegatedRoleByUserId: 'roledelegation/getdelegatedrolebyuserid?userid=',
    isSubscribed: 'subscription/issubscribedd',
    getSubscriptionReqCount: 'subscription/getsubscriptionreqcount?userid=',
    getDatabaseName: 'masterconfig/getclientname?companyid=',
    checkMaskingEnabled: 'masterconfig/checkmaskingenabled?clientCode=',
    isPanicActionTakenByAdmin: 'panicaction/panicactiontakenbyadmin?siteId=',
    showSingleRunningTrip: 'tripdetails/showsinglerunningtrip?tripId=',
    getSpecialBookingForVendor: 'specialbooking/getlist?userid=',
    getAllCostCenter: 'project/costcenter',
    addEditCostCenter: 'project/editcostcenter',
    costCenterUpload: 'project/costCenterUpload?clientCode=',
    changePassword: 'user/changepwd',
    getAll: 'shift',
    add: 'shift',
    update: 'shift',
    getShiftAudit: 'audit/getshiftaudit',
    delete: 'shift?shifttimeid=',
    getAllBySiteId: 'shift/getshiftbysiteid',
    mapSiteShift: "site/updatesiteshift?siteid=",
    getLeaveId: 'employeeleave/getleaveshifts',
    getHolidayId: 'employeeleave/getholidayshifts',
    getWeeklyOffId: 'shift/getweeklyoffshifts',
    getAllBySiteIdGen: 'shift/getbysiteshifttypegeneral',
    getAllSiteByTypes: 'getshiftbytypes',
    getShiftsBySiteAndType: 'shift/getbysiteshifttype?siteid=',
    enabledisableshifttime: 'shift/enabledisableshifttime',
    getShiftTimesBySiteIdShiftTypeAndLogType: 'shift/getshiftbysiteidandshifttypeandlogtype',
    getShiftTimesListBySiteId: 'search/getshifttimesListbysiteid',
    getCancelId: 'employeeleave/getcancelshifts',
    getRoutingConfigShiftsByRoutingId: 'routingconfig/getroutingconfigshiftsbyroutingid',
    getShiftListBasedOnSiteIdAndLogTypes: 'shift/getShiftListBasedOnSiteIdAndLogType',
    getProcessList: 'project/projectslist',
    getAllDepartment: 'project/department',
    addEditDepartment: 'project/editdepartment',
    departmentUpload: 'project/departmentUpload?clientCode=',
    getCompanyInfo: 'user/getcompanyinfo',
    updateCompany: 'company',
    getCityByCompanyId: 'location?companyid=',
    getLocationByCompanyId: 'location/getLocationsByCompany?companyid=',
    cityAudit: 'audit/getlocationaudit?id=',
    cityStatus: 'location/enabledisablelocation?locationId=',
    addEditCity: 'location',
    getSite: "site",
    getSitesByLocationId: 'site/getallsitesbylocation?locationid=',
    getSiteByLocationId: "site/getsitesbylocation?locationid=",
    getActiveSitesByLocation: "site/getactivesitesbylocation?locationId=",
    siteAudit: 'audit/getsiteaudit?id=',
    uploadSites: 'myrisksecure/uploadsites?locationId=',
    getSitesById: "site/getsitesbyid?siteid=",
    getAllVendorMasterForDropDown: 'vendormaster/getallvendormastersfordropdown',
    getAllVehicleType: 'vehicletype',
    addGeoCode: 'landmark/addgeocodesetup',
    updateGeoCode: 'landmark/updategeocodesetup',
    getAllGeoCode: 'landmark/getallgeocodesetupv3',
    getActiveSitesbyUser: 'site/getsitesbyuserid',
    getSitesForDashboard: 'site/getsitesfordashboard',
    getSitesforVendorTrackTrip: 'site/getsitesforvendortracktrip',
    getAllTimeSlots: 'timeslot/alltimeslotlistv3',
    addEditTimeSlot: 'timeslot',
    statusTimeSlots: 'timeslot/updatetimeslotstatus?id=',
    getserchlocationlist: 'search/getlocationlist',
    enableDisableHoliday: 'holiday/enabledisableholiday',
    getserchsitelist: 'search/getsiteslist?locationId=',
    getHolidayList: 'holiday/getholidaylistbysiteidv3?siteId=',
    uploadHolidays: 'holiday/upload',
    getHolidayAuditById: 'audit/getholidaylistaudit?id=',
    enabledisableholiday: 'holiday/enabledisableholiday?id=',
    addHoliday: 'holiday/addholidays',
    updateHoliday: 'holiday/updateholidaydetails',
    getAllProjects: 'project',
    addProject: 'project',
    updateProject: 'project',
    deleteProject: 'project?id=',
    auditProject: 'project/audit?id=',
    getAllCostcenter: 'project/costcenter',
    getProjectSetupAuditById: 'audit/getprojectsetupaudit',
    getDeviceConfigAudit: 'audit/getdeviceconfigaudit',
    enableDisableProject: 'project/enabledisable',
    profileUpload: 'userinfo/uploaduserprofile',
    getAllSubscription: 'subscriptionsetup',
    addSubscription: 'subscriptionsetup',
    updateSubscription: 'subscriptionsetup',
    deleteSubscription: 'subscriptionsetup?subscriptionid=',
    audit: 'subscriptionsetup/audit?id=',
    monthlyCharge: 'subscriptionsetup/monthlycharge',
    template: 'subscriptionsetup/site',
    enabledisablesubsetup: '/subscriptionsetup/enabledisablesubsetup',
    getRole: 'role?page=',
    enabledisablerole: 'role/enabledisable?id=',
    getRoleAudit: 'audit/getroleaudit?id=',
    getMenusList: 'menu/getmenus',
    getAllMenusPageable: 'menu?page=',
    addRole: 'role',
    addMenu: 'menu',
    enableDisableMenu: 'menu/enabledisable?id=',
    getMenuAudit: 'audit/getmenuaudit?id=',
    getAllServerPath: 'serverpath?page=',
    enableDisableServer: 'serverpath/enabledisable?id=',
    getServerPathAudit: 'audit/getserverpathaudit?id=',
    getAllTripMirroringSetup: 'tripmirroringsetup?page=',
    getTripMirroringAudit: 'audit/gettripmirroringsetupaudit?id=',
    checkTripmirroringsetupbysite: 'tripmirroringsetup/site?siteid=',
    addEditTripmirroringsetup: 'tripmirroringsetup',
    enabledisablemirroringsetup: 'tripmirroringsetup/enabledisablemirroringsetup?tripmirroringsetupid=',
    getAllEscalationbyUserType: 'panicsetup?page=',
    getAllRole: 'role/getroles',
    addServerPath: 'serverpath',
    getAdminForEscalation: 'panicsetup?page=',
    getallcovidusers: 'covid/getallcovidusers?searchkey=',
    addEditAdminEscalation: 'panicsetup/admin',
    enableDisableEM: 'panicsetup/enabledisableescalation?id=',
    auditforEM: 'audit/getpanicsetupforadminaudit?id=',
    lateNightSetup: 'latenightsetup/getallsetupsv3',
    enabledDisableLateNightSetup: 'latenightsetup/enabledisable',
    getLateNightBookingSetupAudit: 'audit/getlatenightbookingsetupaudit',
    auditforadminEM: 'audit/getpanicsetupforadminaudit?id=',
    getVendorList: 'vendor/getvendorlist',
    auditforVendorEM: 'audit/getpanicsetupforvendoraudit?id=',
    getBookingSetup: 'bookingsetup',
    addBookingSetup: 'bookingsetup',
    updateBooking: 'bookingsetup',
    getBookingSetupAuditById: 'audit/getbookingsetupaudit',
    enabledisableRouting: 'routingconfig/enabledisableroutingconfig',
    enabledisableBooking: 'bookingsetup/enabledisablebookingsetup',
    getAllBookingViolation: 'bookingviolationsetup/getAllBookingViolationSetups',
    getAuditBookingViolation: 'audit/getbookingviolationaudit?id=',
    enabledisableBookingViolation: 'bookingviolationsetup/enabledisableBookingViolation?id=',
    addbookingviolationsetup: 'bookingviolationsetup/addBookingViolationSetup',
    editBookingViolationSetup: 'bookingviolationsetup/updateBookingViolationSetup',
    getAdhocBooking: 'adhocsetup?pageNo=',
    enableDisableAdhocBooking: 'adhocsetup/enabledisable',
    getProjects: 'project/getprojects?searchkey=',
    auditAdhocbooking: 'audit/getadhocbookingsetupaudit?id=',
    getAdhocTypes: 'adhoctypes',
    routingSetupAudit: 'audit/getroutingconfigauditv3?id=',
    routingSetupAuditDetails: 'audit/getv3routingconfigauditdetails?prevRevId=',
    addEdit: 'adhocsetup',
    getAllJobs: 'scheduler/jobs',
    pauseScheduleJob: 'scheduler/pause?jobName=',
    resumeScheduleJob: 'scheduler/resume?jobName=',
    deleteScheduleJob: 'scheduler/delete?jobName=',
    deleteJobSetup: 'jobsetup?id=',
    addScheduleJob: 'scheduler/schedule?jobName=',
    editScheduleJob: 'scheduler/update?jobName=',
    getModuleList: 'module/getmodules',
    getMastersList: 'vendormaster/getallvendors',
    enabledisablevendormaster: 'vendormaster/enabledisablevendormaster?id=',
    getVendorMasterAuditById: 'audit/getvendormasteraudit',
    getAllVendorDocs: 'vendormaster/getvmdocs?id=',
    getVendorMasterDetailsRefersh: 'vendormaster/get?id=',
    addMaster: 'vendormaster',
    getAllVehicleTypewithParams: 'vehicletype?page=',
    enableDisableVehicleType: 'vehicletype/enabledisablevehicletype?vehicletypeid=',
    vehicleTypeAudit: 'audit/getvehicletypegaudit?id=',
    addEditVehicleType: 'vehicletype',
    addVendor: 'vendor',
    updateVendor: 'vendor',
    getAllRoles: 'role/getroles',
    getNotificationAudit: 'audit/getnotificationsaudit?id=',
    enableDisableNotification: 'notification/enabledisable?id=',
    getAllNotification: 'notification?page=',
    addEditNotification: 'notification',
    getvehiclelistbyvendormasterid: 'vehicle/getvehiclelistbyvendormasterid?vendorMasterId=',
    getSiteslistbyvehicleIdAndVendormasterId: 'vehicle/getsitevehiclebyvehicleidandvendorid?vehicleId=',
    getVendorByUserId: 'vendor/getvendorbyuserid?userid=',
    updatevehiclesite: 'vehicle/updatevehiclesite?vehicleid=',
    getLocationsByCompanyIdAndUser: 'location/getlocationbyuser?companyId=',
    masterZone: 'masterzone?siteid=',
    addMasterZone: 'masterzone?zonename=',
    updateshiftmap: 'masterzone/updateshiftmap?zoneids=',
    deleteMasterZone: 'masterzone?zoneid=',
    cloneMasterZone: 'masterzone/clone?zoneid=',
    zone: 'zone?zoneid=',
    addZone: 'zone',
    getZones: 'zone?masterzoneid=',
    getzonebyid: 'zone/zoneByid?zoneid=',
    getarealist: 'search/getarealist?locationId=',
    getAreaListByLocation: 'search/getareasbylocation?locationId=',
    getArea: 'area',
    addLandmark: 'landmark',
    landmarkCountbytype: 'landmark/landmarkcountbytype?locationId=',
    getlandmarkbytype: 'landmark/landmarkbytype?location=',
    getalllandmarksbylocation: 'landmark/getalllandmarksbylocation?locationId=',
    deleteLandmark: 'landmark/removelandmark?landmarkid=',
    updateBoundary: "site/updateboundary",
    deleteBoundary: "site/deleteboundary?siteid=",
    getBoundaryAudit: "site/boundaryaudit?siteid=",
    getVendorAuditById: 'audit/getvendoraudit',
    getuploaderror: 'landmark/getuploaderror?fileid=',
    getreportingofficer: 'search/getreportingofficerslist?currentUserId=',
    getAllUser: 'user/getallusers',
    getsitebyshift: 'shift/getbysiteshifttypegeneral?siteid=',
    getSiteListForDropDown: 'site/getsitelist',
    getSchedulingReport: "report/schedulingreport",
    getAllEscort: 'escort/getescort',
    getAllEscort1: 'escort/getallescort',
    addEscort: 'escort',
    updateEscort: 'escort',
    deleteEscort: 'escort?id=',
    enabledisable: 'escort/enabledisableescort',
    getuploaderrorEscort: 'escort/getuploaderror',
    auditEscort: 'audit/getescortaudit',
    getAllEscorts: 'escort/getallescort',
    addingEscortToTrip: 'tripdetails/addingescort',
    uploadEscort: 'escort/escortupload',
    getAdhocBookingReport: "report/adhocbookingreport",
    getEmployeeNoShowReport: "report/getemployeenoshowReport",
    getEmployeetripDetails: 'report/gettripwiseemployeenoshowReport',
    getMedicalEmployeeReport: "report/getmedicalemployeereport",
    getMedicalEmployeeListForDownloadReport: "report/getmediaclemployeeslistfordownloadreport?siteId=",
    getPanicActionReport: "report/panicactionreport",
    getProjectWiseNoShowReport: "report/projectwisenoshowreport?siteId=",
    getProjectWiseNoShowSummaryReport: "report/projectwisenoshowsummaryreport?siteId=",
    getTripWiseEmployeeNoShowReport: "report/gettripwiseemployeenoshowReport?siteId=",
    getDriversByVendorMasterId: 'driver/getdriversbyvendormasterid?vendorMasterId=',
    updatevehicledriver: 'vehicle/updatevehicledriver?vehicleid=',
    getDriverListByVehicleId: "driver/getdriversbyvehicleid?vehicleId=",
    getEmpUnderSpoc: 'user/getuserlistbyspoc?spocid=',
    getUser: 'user?page=',
    getUserList: 'user/getalluserslist?page=',
    userStatus: 'userinfo/enabledisable?id=',
    getUserInfoDetailsAuditById: 'audit/getuserinfodetailsaudit?id=',
    uploaduserfields: 'user/uploaduserfields',
    getusersbyspoc: 'user/getusersbyspoc?spocid=',
    getallusersinfo: 'covid/getallusersinfo',
    getUploadError: 'user/getuploaderror?fileid=',
    getreportingofficerslist: 'search/getreportingofficerslist?currentUserId=',
    getUserInfoDetails: 'user/getbyuserinfoid?id=',
    getAllUserDocs: 'user/getbyuserinfoid?id=',
    getSpocUsersLists: 'search/getspocuserslistwithoutsiteid?currentUserId=',
    getcommonpointlandmarkslist: 'search/getcommonpointlandmarkslist?areaId=',
    getrolelist: 'search/getrolelist',
    getprojectslist: 'search/getprojectslist',
    getAllbusinessunitcode: 'businessunitcode',
    getAllcategory: 'category',
    addUser: 'userinfo/v3/adduserdetails',
    editUser: 'userinfo',
    getActiveVehiclesCountByVendorMasterIdAndSiteId: 'vehicle/getactivevehiclescountbyvendormasteridandsiteid?siteid=',
    getVehicleCertificateExpiryDateIsNull: 'vehicle/getvehiclecertificateExpirydateisnull?siteid=',
    getVehicleDocumentsStatusCounts: 'vehicle/getVehicleDocumentsStatusCounts?siteid=',
    getAllVehicles: 'vehicle?page=',
    getVehiclesListByCertificateExpiryDateIdNull: 'vehicle/getvehiclelistbycertificateexpirydateisnull?siteid=',
    getvehicleexpirybyvendormasteridandsiteidandtypeandstat: 'vehicle/getvehicleexpirybyvendormasteridandsiteisandtypeandstat?siteid=',
    enableDisableVehicle: 'vehicle/enabledisablevehicle?vehicleid=',
    vehicleAudit: 'audit/getvehicleaudit?id=',
    getAllVehiclesBySiteIdAndVendorMasterId: 'vehicle/getallvehiclesbysiteidandvendormasterid?vendormasterid=',
    generateQrCode: 'qrcode/generateQrCode?qrCodeData=',
    upload: 'upload',
    addEditVehicle: 'vehicle',
    checkVehicleBySiteId: 'vehiclechecklistsetup/getbysiteid?siteId=',
    getByvendor: 'driver/getdriverslistbyvendor?vendormasterid=',
    vehicleCheckList: 'vehiclesitechecklist',
    getBySiteId: 'vehiclesitechecklist?vehicleId=',
    getVehicleByid: 'vehicle/getvehiclebyid?vehicleid=',
    getSubscribedEmpUnderSpoc: 'subscription/getsubscriptionlistforspoc',
    getAllSubscriptionSetups: 'subscription',
    updateSubscription1: 'subscription',
    addUserSubscription: 'subscription/add_user_subscription',
    getAllUnSubscriptionsForSpoc: "subscription/getunsubscriptionlistforspoc",
    getAllUnsubscriptions: 'subscription/unsubscriptions',
    getSubscriptionList: 'subscription/subscriptionlist',
    updateUnSubscriptionList: 'subscription/updateunsubscriptiondate',
    getAllSuspensionSubscriptions: "subscription/getAllSuspensionSubscriptions",
    suspendSubscription: 'subscription/suspendSubscription',
    revokeSubscription: 'subscription/revokeSubscription',
    getEmpUnderSpoc1: 'user/getusersunderspoc',
    getAllCovidUser: 'covid/getallcovidusers',
    downloadSubscription: 'subscription/downloadsubscription',
    getEscortReport: "report/getescortreports?siteId=",
    getEscortListBySiteId: "escort/getescortlist?siteid=",
    getSubscriptionAuditById: 'audit/getsubscriptionaudit',
    getPendingGeoCode: 'user/getpendinggeocodes',
    updatePendigGeoCode: 'user/updatependingapproval',
    masterZone1: 'masterzone',
    getActiveDriversCountByVendorMasterId: "driver/getactivedriverscountbyvendormasterid?vendormasterid=",
    getDrivertByCertificateExpiryDateIdNull: 'driver/getdrivercertificateExpiryateisnull?vendormasterid=',
    getDriverDocumentsStatusCounts: 'driver/getDriverDocumentsStatusCounts?vendormasterid=',
    getByVendorMaster: 'driver?page=',
    getAllDriversByAdmin: 'driver/alldrivers?page=',
    getActiveDriversCount: 'driver/getactivedriverscount',
    getDriverDocumentExpiry: 'driver/getdrivercertificateExpiry',
    getDriverDocumentStatusCounts: 'driver/getdriverdocumentStatusCount',
    getDriverListByCertificatesDateIsNull: 'driver/getdriverlistsbycertificatesExpirydateisnull?type=',
    getDriversListByCertificateDateIsNull: "driver/getdriverslistbycertificateExpirydateisnull?vendormasterid=",
    getdriverexpiryByTypeandstat: 'driver/getdriverexpirybytypeandstat?type=',
    getdriverexpirybyvendormasteridandtypeandstat: "driver/getdriverexpirybyvendormasteridandtypeandstat?vendormasterid=",
    getDriverListsByAdmin: 'driver/getdriverslist?searchkey=',
    getDriverListByVendorMasterId: 'driver/getdriverslistbyvendormasterid?vendormasterid=',
    enabledisabledriver: 'driver/enabledisabledriver?driverid=',
    getDriverAuditById: 'audit/getdriversaudit?id=',
    addEditDriver: 'driver',
    getDriverById: 'driver/getdriverbyid?id=',
    unregisterDriver: 'driver/unregisterdriver?updateById=',
    getTripPushBackReport: 'report/trippushbackreport',
    getEmployeeAppReportthis: 'report/getemployeeappreport',
    getOutOfBoundaryEmployeesReportthis: 'landmark/getemployeesoutsideboundary',
    getTripStatmentReport: "report/tripstatmentreport",
    getReportDateWise: 'report/getalltripreport',
    getTripStatmentSummaryReport: "report/tripstatmentsummaryreport",
    latenightcab: 'latenightcab',
    submitlatenightcab: 'latenightcab/latenightbookingsforemp',
    cancelbooking: 'latenightcab/cancelbooking',
    lateNightShift: 'shift/latenightshift',
    searchUserByFirstName: 'user/searchuserbyfirstname',
    getLateNightBookingAuditById: 'audit/getlatenightbookingaudit',
    getTripReport: 'report/gettripreport',
    getTripsReport: "report/getfulltripreport",
    getSiteShiftTimes: 'site/getsiteshifttimesbylocationid?locationId=',
    getBookingSetupBySiteId: 'bookingsetup/getBookingSetupById?siteId=',
    getUserBySiteId: 'subscription/getsubscriptionbysiteid?siteid=',
    downloadBookings: 'booking/download?siteid=',
    uploadBookings: 'booking/upload',
    getSpocProcess: 'project/getspocprojects?spocid=',
    getByUsers: 'booking/getbookingsbyusersweb?siteId=',
    getLeaveDetails: 'employeeleave/getemployeeleavedetails?fromdate=',
    holidayDetails: 'employeedevice/getholidaylist?siteId=',
    saveBooking: 'booking/submitbookingsweb',
    getSiteBySerch: 'site/getsitesbyname?searchkey=',
    getAllAdhocBookigView: 'adhocbooking/getalladhocbookingsview',
    getuserbyfirstname: 'user/getuserbyfirstname',
    getAdhocTypesBySiteIdAndUserId: 'adhocsetup/getadhocbysiteidandemployeeid',
    checkBookings: 'adhocbooking/getempbookingsforvalid',
    getAdhocByUsers: 'adhocbooking/getadhocbookings',
    getAdhocSetupBySiteIdAndPickUpDrop: 'adhocsetup/getadhocsetupbysiteidandpickupdrop',
    getAllPickDropBySiteIdAdhocTypes: "adhocsetup/getpickdropbysiteidandadhoctypes",
    getShiftTimeForEmp: 'adhocbooking/getshifttimeforemp',
    getAllRoutePriority: 'routepriority',
    getbyid: 'masterzone/getbyid',
    getzonebyshift: 'masterzone/getzonebyshift',
    getzoneshiftmap: 'masterzone/getzoneshiftmap',
    addShiftPickPoint: 'shiftpickpointmap',
    getShiftPickPoint: 'shiftpickpointmap/get',
    getSpecialBookingSetups: "specialbookingsetup/getallspecialbookingsetups",
    addspecialbookingsetup: "specialbookingsetup/addSpecialBookingSetup",
    updateSpecialBookingSetup: "specialbookingsetup/updatespecialbookingSetup",
    enabledisablespecialbookingsetup: "specialbookingsetup/enabledisablespecialbookingsetup",
    getSpecialBookingSetupAudit: "audit/getspecialbookingsetupaudit",
    getVendorsListPageable: 'vendor/getallvendorusers',
    getAllAdhocPendingsBySiteIdAndSpocId: 'adhocbooking/getAllAdhocPendingsBySiteIdAndSpocId',
    cancelAdhoc: 'adhocbooking/cancelbooking',
    approveAdhoc: 'adhocbooking/approvebooking',
    getAdhocBookingAuditById: 'audit/getadhocbookingaudit',
    addAdhocBooking: 'adhocbooking/submitadhocbookings',
    addAdhocBooking1: 'adhocbooking/submitadhocbookings1',
    getShiftTimesForSiteId: 'shift/getshiftbysiteid',
    getTripUsageTripDetailsReportdata: 'report/tripdetailsbytripid',
    getTripUsageSummaryReport: 'report/tripusagesummaryreport',
    getUserExtProjects: 'project/getuserextproject',
    getAllRoutingConfigBylocationId: 'routingconfig/getroutingconfigbylocationid',
    addRoutingConfig: 'routingconfig',
    updateRoutingConfig: 'routingconfig',
    getFixedRouteTemplate: 'route/getrouteexcelformat',
    getRoutes: 'route/downloadroutes',
    uploadRoutes: 'route/uploadroutes',
    getroutebysiteidandtype: 'route/getroutebysiteidandtype',
    getAreaByRouteId: 'route/getareabyrouteid',
    getLandmarksByAreaId: 'route/getlandmarksbyareaid',
    deletelandmarkfromroute: 'route/deletelandmarkfromroute',
    deleteareafromroute: 'route/deleteareafromroute',
    deleteroutebyrouteid: 'route/deleteroutebyrouteid',
    addRoute: 'route',
    getlandmarksbylocationidandtype: 'route/getlandmarksbylocationidandtype',
    addlandmarkstoroute: 'route/addlandmarkstoroute',
    getareabylocationid: 'route/getareabylocationid',
    addareastoroute: 'route/addareastoroute',
    enabledisablevendor: 'vendor/enabledisablevendor?id=',
    vendorAssignedTripCount: "tripdetails/getVendorAssignedAndNotAssignedTrips",
    getVendorAssignedTrips: 'tripdetails/getVendorAssignedTrips',
    getRoutingConfigBySiteId: 'routingconfig/routingcongigbysiteid',
    updateVendorTripAssign: 'tripdetails/updatetripassign',
    updateVendorTripAssignV3: 'tripdetails/updatetripassignv3',
    updateTripTravelTime: 'tripdetails/updatetriptraveltime',
    updateTripTotalTime: 'tripdetails/updateemptraveltime',
    updateTripVendorAllocations: 'tripdetails/updatetripVendorallocations',
    getSpecialbookings: 'specialbooking',
    allowDriverAlocForAdmin: 'specialbookingsetup/allowadminfordriverallocation?siteId=',
    getSpecialbookingType: 'specialbooking/bookingtype',
    getVehicleBySiteVendorVehicleType: 'vehicle/getlistbysitevendorandvehicletype',
    addSpecialBooking: 'specialbooking/adminspecialbookingforweb',
    updateSpecialBooking: 'specialbooking/updatespecialbooking',
    getAllPrePopulatedLandmarks: 'specialbooking/getprepoulatedlandmarksbysite?siteId=',
    updateSpecialBookingStatus: 'specialbooking',
    resendOtp: 'tripdetails/sendotp',
    getTripViewAuditById: 'audit/gettripsauditv3',
    getOSMRoute: 'osrm/getdirectionresult?tripid=',
    getDeviceTripStatus: 'tripdetails/getdevicetripstatus',
    accidentalStop: 'tripdetails/accidentalstop',
    fetchgeoposition: 'fetchgeoposition',
    gettripbytripid: 'tripdetails/gettripbytripid',
    sendBackToAdmin: 'tripdetails/trippushback',
    sitebyLocId: 'site/getsitesbylocation?locationid=',
    uploadSpecialBooking: 'specialbooking/uploadspecialal',
    getTripUsageReport: 'report/tripusagereport',
    getspecialcategorybysiteidandspecialcategoryid: 'specialCategorySetup/getspecialcategorybysiteidandspecialcategoryid',
    getvehicleroutemapbysiteidandzoneid: 'vehicleroutemap/getvehicleroutemapbysiteidandzoneid',
    addSpecialCategory1: 'vehicleroutemap',
    addSpecialCategory: 'specialCategorySetup',
    getAllCategory: 'category',
    getNavigationSetupByLocationId: "navigationSetup/getNavigationSetupByLocationId",
    addNavigation: 'navigationSetup/addNavigationSetup',
    updateNavigation: 'navigationSetup/updateNavigationSetup',
    enabledisableNavigation: 'navigationSetup/enableDisableNavigationSetup',
    navigationAudit: 'navigationSetup/audit?id=',
    getShiftTimeListByNavigationId: 'navigationSetup/getNavigationSetupShiftsById',
    getexceptionemployeesearch: 'user/getexceptionemployeesearch',
    getSites: "site/getsitesbyid",
    getallmasterroute: 'tripdetails/getallmasterroute',
    deletemasterroute: 'tripdetails/deletemasterroute',
    saveallmasterroute: 'tripdetails/saveallmasterroute',
    getTripUsageTripDetailsReport: "report/tripdetailsbytripid",
    getVehicleUsageReport: 'report/vehicleusagereport',
    getVehicleUsageReports: 'report/vehicletripreport',
    updateSpecialbooking: 'specialbooking',
    deleteVendorAllocation: 'tripdetails/getallvendorassignedtrips?siteid=',
    deleteAllocatedtrip: 'tripdetails/deletevendorassign',
    getAllSafeDropSetup: 'safedrop/getallsetupv3',
    enableDisableSafeDropSetup: 'safedrop/enabledisablesetup',
    editSafeDropSetup: 'safedrop/editsetup',
    addSafeDropSetup: 'safedrop/addSetup',
    getAdjustmentTrips: 'adjustmenttrip/getalladjustmenttrips',
    getMapAudit: 'audit/getmapsetupaudit?id=',
    enableDisableMapSetup: 'mapsetup/enabledisable?id=',
    getMapSetup: 'mapsetup?page=',
    addMapSetup: 'mapsetup',
    updateMapSetup: 'mapsetup',
    getSmsAudit: 'audit/getmapsetupaudit?id=',
    enableDisableSmsSetup: 'smssetup/enabledisable?id=',
    getSmsSetup: 'smssetup?page=',
    addSmsSetup: 'smssetup',
    updateSmsSetup: 'smssetup',
    getAllPw: 'customauth/getcustomauthdetails',
    addPw: 'customauth/addcustomauthdetails',
    updatePw: 'customauth/updatecustomauthdetails',
    enableDisablePw: 'customauth/enabledisablecustomauthdetails?id=',
    getPWSetupAuditById: 'audit/getcustomauthdetailsaudit?id=',
    getEmployeeAddressChangeReport: 'report/getempaddresschangereport',
    viewRoleDelegations: 'roledelegation/getroledelegateddetails',
    getEmployeeUnderSpoc: 'user/getusersunderspoc',
    addRoleDelegation: 'roledelegation/addroledelegation',
    getRoleDelegationAuditById: 'audit/getroledelegationaudit',
    updateRoleDelegation: 'roledelegation/updateroledelegation',
    getWeeklyRoaster: 'booking/isweeklyroasteropened',
    getWeekly: '/booking/getweeklyroaster',
    saveRoaster: 'booking/submitweeklyroaster',
    getAlter: '/booking/getalterroaster',
    company: 'company',
    updateAdjustmentTrip: 'adjustmenttrip?id=',
    tripstats: 'tripdetails/tripstatsshow?date=',
    tripDetailsGenerate: 'tripdetails/generate',
    isNightShift: 'tripdetails/isnightshift',
    tripTemplate: 'tripmirroringsetup/site?siteid=',
    generateMirroring: 'tripdetails/generatemirroring',
    getAllVendorDropDownBasedOnSite: 'vendormaster/getallvendorfordropdown?siteId=',
    saveMirroring: 'tripdetails/savemirroring',
    saveAllTripMirrorTemp: 'tripdetails/savealltripmirrortemp',
    saveTripMirrorTemp: 'tripdetails/savetempmirroring',
    getTripSummary: 'tripdetails/tripstatssummary?siteid=',
    fetchTripSummary: 'tripdetails/tripstatssummaryV3?siteid=',
    updateEmployeePickUpTime: 'tripdetails/updateemppickuptime?siteId=',
    getTripSheet: "tripdetails/gettripsheetv3?siteid=",
    gettripsbytripids: 'tripdetails/gettrips?tripids=',
    gettripsbytripids1: 'tripdetails/gettrips?',
    getIvrCalls: 'ivrcall/getivrcallsreportV3?siteId=',
    gettripuploaderrorlist: 'tripdetails/gettripuploaderrorlist?fileid=',
    getdirectionresult: "osrm/getdirectionresult?tripid=",
    updateTravelShowStatus: 'tripdetails/updatetravelshowstatus?empId=',
    getAllException: 'tripdetails/getAllException?date=',
    getreplaysummary: 'livetracking/getreplayreport',
    getreplaysummarydata: 'livetracking/getreplaysummarydata',
    vehiclePosition: 'fetchallgeoposition',
    fetcheta: 'livetracking/fetcheta',
    getAllVendorDropDownBasedOnSiteAndUser: 'vendormaster/getallvendorbyuser?siteId=',
    get_state_district_wise: 'covid/get_state_district_wise',
    //runningTripShow: 'tripdetails/runningtripshow',
    runningTripShow: 'tripdetails/runningtripshowv3',
    showshiftwisetrip: 'tripdetails/showshiftwisetrip?siteid=',
    //showspecialbookingtrips: 'tripdetails/showspecialbookingtrips?siteid=',
    showspecialbookingtrips: 'tripdetails/showspecialbookingtripsv3?siteid=',
    liveTrakingBaseURL: 'fetchgeoposition',
    filterlivetracking: 'livetracking/showshiftwiselivetrip?siteId=',
    getallemppickupdropdetails: 'livetracking/getemppickupdropdetails',
    getLiveTracking: 'tripdetails/runningtriptableshow?siteid=',
    showRuningTrips: 'tripdetails/showrunningtrip?siteid=',
    fetchuserhomepickup: 'livetracking/fetchuserhomepickup?tripId=',
    getVehiclePosition: 'fetchallgeoposition?tripId=',
    getPanicStopTrips: 'panicaction/panicstoptrips',
    getPanicActionTripsBasedOnSite: 'panicaction/getpanicstoptrips',
    approvePanicStopTrip: 'panicaction/approvepanicstoptrip',
    getPunchInOutReport: 'report/emppunchinoutreport',
    getOtaOtdReportitem: 'report/getotaotdtripdetails',
    getOtaOtdSummaryReport: 'report/getotaotdsummary',
    getReplaySummaryData: 'livetracking/getreplaysummarydata',
    fetchUserHomePickup: '',
    getEmailSmsCountReport: 'report/getreportofemailsmscount',
    getSpecialBookingReport: "report/specialbookingreport",
    getVehicleMisDetailsReport: "report/vehiclemisdetails",
    getTripSheetReport: "report/gettripsheetreport",
    getTripSheetReportExportDetails: 'report/gettripsheetreportexportdetails',
    getSubscriptionReport: "report/subscriptionreport",
    getActiveSubscriptionsReport: "report/activesubscriptionsreport",
    getAdjustmentTripReport: "adjustmenttrip/getalladjustmenttripsreport",
    getunassignedtrips: 'tripdetails/getunassignedtrips?date=',
    allocatevendor: 'tripdetails/allocatevendor',
    getExceptionAllSubscribedUser: 'user/getallexceptionsubscribedusers?searchkey=',
    gettripmodifypreview: "osrm/gettripmodifypreview?logtype=",
    modifytrip: 'tripdetails/modifytrip',
    updateEscortStatusByAdmin: "tripdetails/updateescortstatusbyadmin?tripId=",
    getgeneratedtripcount: 'tripdetails/getgeneratedtripcount?date=',
    allocatevendorbyvehicletyperatio: 'tripdetails/allocatevendorbyvehicletyperatio',
    getZoneByTripId: 'tripdetails/getzonebytripid?tripId=',
    getZonesByMasterZoneId: 'zone/getrelatedzonesbymasterzoneidandsiteidandlocationid?masterZoneId=',
    deletetrip: 'tripdetails/deletetrips',
    getAutoStopReport: "report/getautostopreport?siteId=",
    resetTripStatus: 'tripdetails/resettripstatus?tripId=',
    getDriverAppReport: 'report/getdriverappreport',
    getUnSubscriptionReport: "report/unsubscriptionreport",
    getUnSubscriptionsReport: "report/unsubscriptionsreport",
    getExceptionReport: "report/getexceptionsreport",
    removeVehicleAllocation: 'tripdetails/removevehicleallocation',
    getVehicleOccupancyReport: "report/vehiclesoccupencyreport?siteId=",
    getAllIvr: 'ivrsetup',
    addConfig: 'ivrsetup',
    updateConfig: 'ivrsetup',
    getIvrConfigByDescription: 'ivrsetup/getIvrConfigByDescription',
    getSitesListByLocationId: 'search/getsiteslist?locationId=',
    getLocationList: 'search/getlocationlist',
    getTransportType: 'transport/getTransportType',
    getAllPayRoll: 'payroll/getpayrollconfigdetails',
    addPayRoll: 'payroll/payrollconfig',
    updatePayRoll: 'payroll/updatepayrollconfig',
    enableDisable1: 'payroll/enabledisablepayrollconfig',
    deletePayRoll: 'payroll/payrollconfig?id=',
    auditPayRoll: 'audit/getpayrollconfigaudit?id=',
    transport: 'transport/getTransportType',
    getPayRollSetupAuditById: 'audit/getpayrollconfigaudit',
    getAllVendorMasterList: 'vendormaster/getvendormasterlist',
    suspendSubscriptionEnable: 'subscription/suspendsubscriptionv3',
    getUnsubscriptionList: 'subscription/unsubscriptionlist',
    cancelBooking: 'latenightcab/cancelbooking',
    getSpecialBookingAuditById: 'audit/getspecialbookingaudit',
    cancelAdhocBooking: 'adhocbooking/cancelbooking',
    getMasterConfigurationAudit: 'audit/getmasterconfigurationaudit',
    enableDisableConf: 'masterconfig/enabledisable',
    deleteConfig: 'masterconfig?masterconfigId=',
    getAllConfig: 'masterconfig/masterconfig',
    listOfcompanies: 'company',
    deleteCompany: 'company?companyid=',
    enableDisable: 'emailsetup/enabledisable?id=',
    getCompanyAudit: 'audit/getcompanyaudit?id=',
    companies: 'company/getcompanies',
    masterconfig: 'masterconfig',
    getMasterConfigsByCompanyId: 'masterconfig/getcompany?companyid=',
    emailsetup: 'emailsetup',
    getAllemail: 'emailsetup?page=',
    getEmailSetupAudit: 'audit/getemailsetupaudit?id=',
    deleteEmail: 'emailsetup?id=',
    getUserExperienceDetails: 'userexperienceanswers/getuserexperiencereport',
    getUserExperienceAnswerHeader: 'userexperienceanswers/getuserexperienceheaders',
    updateSubscriptionCancel: 'subscription',
    updateDriverDevice: 'driverdevice/updatconfig',
    addNewConfig: 'driverdevice/addconfig',
    getAllStatus: 'driverdevice/getconfigdata',
    getSuspendSubscriptionAudit: 'audit/getSuspendSubscriptionAudit',
    getVehicleTypeBySite: 'vehicletype/getvehicletypebysite',
    getVehicleTypesBySiteId: 'vehicletype/getvehicletypebysiteid',
    getEmployeePanicbytripid: 'employeepanic/getemployeepanicbytripid?tripId=',
    updatePanicActionForAdmin: 'panicaction/updatePanicAction?employeeId=',
    adjustmentTrip: 'adjustmenttrip',
    getVehicleBySiteIdVendorIdVehicleTypeId: 'vehicle/getlistbysiteidvendoridandvehicletypeid?siteid=',
    getActiveVehicleTypes: 'vehicletype/getactivevehicletypes',
    addDriver: "driver",
    addVehicle: 'vehicle',
    getActiveOnCallVehicles: 'vehicle/getactiveoncallvehicles',
    getActiveOnCallDrivers: "driver/getactiveoncalldriver",
    updatevehicleDriver: 'vehicle/updatevehicledriver',
    uploadRateCardForOnCallBooking: 'specialbookingsetup/uploadRateCardForOnCallBooking',
    createSafeDropReport: 'safedrop/safedropreport',
    getVehicleNo: 'vehicle/getactivevehiclesbysitevendorandtype?siteid=',
    oncallTrips: 'specialbooking/onCallBookingReport?siteId=',
    getUserInfo: 'tripdetails/getTripSummaryCountByPerson?tripId=',
    filterlivetrackingv3: 'livetracking/showshiftwiselivetripv3?siteId=',
    getLiveTrackingv3: 'tripdetails/runningtriptableshowv3?siteid=',
    removeZoneShift: 'masterzone/removezoneshiftmap?zoneShiftMapId=',
    getTripCancellation: 'report/gettripcancellationReport?siteId=',
    allowToChooseAl: 'landmark/allowtochoosearealandmark',
    updateAddressChangeApproval: 'user/updateaddresschangeapproval',
    showShiftWisetripsv3: 'tripdetails/showshiftwisetripsv3?siteId=',
    addUpdateConsentForm: 'consentForm/addUpdateConsentForm',
    getAllConsentForms: 'consentForm/getAllConsentForms',
    getConsentTypes: 'consentForm/getConsentTypes',
    getConsentedUsersReport: 'consentUsers/getConsentedUsersReport',
    isConsentedUser: 'consentUsers/isConsentedUser',
    saveConsentUser: 'consentUsers/saveConsentUser',
    getOnCallSpecialBookings: 'specialbooking/getspecialbookings',
    getActiveOnCallVehiclesnew: 'vehicle/getactiveoncallvehiclesnew',
    getBookingCancellation: 'report/getbookingcancellationReport?siteId=',
    noshowsetup: 'noshowsetup/getallsetupforv3',
    uploadRateCard: 'specialbookingsetup/uploadOnCallRateCard',
    allowEmpVendorSelection: 'specialbookingsetup/allowempvendorselection',
    getRateCardBySiteAndVendor: 'specialbookingsetup/getRateCardBySiteAndVendor',
    getRateCardBySite: 'specialbookingsetup/getRateCardWithOnCallBookVehicleTypes',
    getAllRatecard: 'specialbookingsetup/getRateCardSetupBySite',
    onCallBookingAcceptedByVendorMaster: 'specialbooking/acceptOnCallBooking',
    gettripsheetforexport: 'report/gettripsheetforexport',
    getlateNightShift: 'shift/latenightshiftv3?siteid=',
    getSsoLoginUrl: 'sso/weblogin',
    getPlannedTrips: 'dashboard/getplanningtrips',
    getExcutionTrips: 'dashboard/getdashboardexecutioninfo',
    safetyDashboard: 'dashboard/getdashboardsafteyinfo',
    dashboardTrips: 'dashboard/dashboardsummary',
    getTrendData: 'dashboard/getdashboardtrendsinfo',
    allowToChooseByLocation: 'specialbookingsetup/allowtochoosebylocation',
    getVendorMasterByLocation: 'vendormaster/getvendormasterbylocation',
    getAllPrePopulatedLandmarksByLocation: 'specialbooking/getprepoulatedlandmarksbylocation',
    getEscortListByLocation: 'escort/getescortsbylocation',
    getVehicleTypeByLocationId: 'vehicletype/getvehicletypebylocationid',
    getVehiclesByVendorMasterAndVehicleType: 'vehicle/getvehiclesbyvendormasterandvehicletype',
    getAllLocationList: 'search/getalllocationlist',
    getAllSitesByLocation: 'site/getallactivesitesbylocation',
    getPeriodicSummaryInfo: 'dashboard/getdashboardperiodicsummaryinfo?fromDate=',
    billingExceptionReport: 'billing/getBillingExceptionsReport?siteId=',
    billingExceptionView: 'billing/getbillingexceptions?siteId=',
    updateBillingSts: 'billingexceptions/updateexceptionstatus?id=',
    userAuditForV3: 'audit/getv3userinfoaudit?id=',
    userAuditForV3OnClick: 'audit/getv3userinfoauditdetails?prevRevId=',
    subscriptionSetup: 'audit/getv3subscriptionsetupaudit?id=',
    subscriptionSetupOnClick: 'audit/getv3subscriptionsetsupauditdetails?prevRevId=',
    getOncallSetupBySite: 'specialbookingsetup/getoncallsetupbysite',
    getlocationlistnew: 'newuser/getlocationlistnew', //  its an open api, it is used for new register user .
    addTempUserv3: 'newuser/addtempuserv3',
    getLateNightReport: "report/getlatenightreport",
    addWebFcmToken: "user/addwebfcmtoken",
    getInAppNotificationCount: "inappnotification/getinappnotificationcount",
    getLatestInappNotifications: "inappnotification/getlatestusernotifications",
    markNotifications: "inappnotification/marknotifications",
    getOnCallReport: 'report/specialbookingreportv3',
    getusersdata: 'user/getusersidandname',
    getUsersBySite: 'user/getuserbysite?siteId=',
    onCallBillingException: 'billing/getoncallbillingexceptions?siteId=',
    onCallBillingExpReport: 'billing/getOncallBillingExceptionsReport?siteId=',
    uploadVehicleDocs: 'vehicle/uploadVehicleDocs',
    updateOncallBillingExcep: 'billingexceptions/updateoncallexceptionstatus?id=',
    uploadDocDriver: 'driver/uploadDriverDocs',
    showshiftwisetripv3: 'tripdetails/showshiftwisetripv3?siteid=',
    showShiftWiseCountForOncall: 'tripdetails/showshiftwisetripsocv3?siteId=',
    getonCallLiveTrackingv3: 'tripdetails/runningtriptableshowoncallv3?siteid=',
    getAllBanners: 'bannerSetup/getAllBannersv3',
    addBanner: 'bannerSetup/addBannerSetup',
    enableDisableBanner: 'bannerSetup/enableDisableBanner',
    getBillingReportAccess: 'user/getbillingReportaccess',
    addManagementBroadcastSetup: 'inAppCommunicationService/send',
    checkAccess: 'inAppCommunicationService/getCommunicationAccess',
    getAllProjectv3: 'project/getallprojectsv3',
    tripsAddedByDriver: 'tripdetails/driverTripsReport',
    tripsAddedByDriverExport: 'tripdetails/driverTripUsersExcel',
    getOverSpeedReport: 'report/getoverspeedreport',
    getncnsReport: 'report/noshowreport',
    getVendorMasterListv3: 'vendormaster/getvendormasterlistv3?page=',
    getAllDepartmentv3: 'project/departmentv3',
    getAllCostCenterv3: 'project/costcenterv3',
    getAllBookingViolationv3: 'bookingviolationsetup/getAllBookingViolationSetupsv3',
    getSpecialBookingSetupsv3: 'specialbookingsetup/getallspecialbookingsetupsv3',
    getAllConsentFormsv3: 'consentForm/getAllConsentFormsv3',
    dashboardTripsEmail: 'dashboard/dashboardsummarybydate?fromDate=',
    getPlannedTripsEmail: 'dashboard/getplanningtripsbydate?fromDate=',
    getExcutionTripsEmail: 'dashboard/getdashboardexecutioninfobydate?fromDate=',
    safetyDashboardEmail: 'dashboard/getdashboardsafteyinfobydate?fromDate=',
    getContextSearchEmployeeTrips: 'contextsearch/gettrips',
    getContextSearchEmployeeSchedule: 'contextsearch/getschedule',
    getContextSearchEmployeeAddress: 'contextsearch/getaddress',
    getContextSearchEmployeeGeocode: 'contextsearch/getgeocode',
    getContextSearchDriverTrips: 'contextsearch/gettripsfordriver',
    getContextSearchDriverDocuments: 'contextsearch/getdriverdocument',
    getContextSearchVehicleTrips: 'contextsearch/gettripsforvehicle',
    getContextSearchVehicleDocuments: 'contextsearch/getvehicledocuments',
    getVehiclesBasedOnRegistrationNo: 'contextsearch/getvehicles',
    getMergeTrip: 'tripdetails/mergetrip',
    ivrExportToExcelReport: 'ivrcall/getivrcallsreportExcelV3?siteId=',
    getAllRoutingConfigBylocationIdv3: 'routingconfig/getroutingconfigbylocationidv3?locationid=',
    getOncallAdminView: 'specialbooking/oncalladminview',
    getStoporRestartTrip: 'tripdetails/stoporrestarttrip',
    tripAuditDetailed: 'audit/gettripsauditdetailsv3?prevRevId=',
    getAllShiftBySiteIdAndLogTypev3: 'shift/getShiftsBasedOnSiteIdAndLogType?siteId=',
    getEmployeeAppReportV3: 'report/getemployeeappreportv3?siteIds=',
    bulkWelcomeEmail: 'bulkEmail/welcomeEmail',
    getTripTimeReport: 'report/gettriptimeReport',
    getNoshowTripReport: 'report/noshowretripport',
    getNoshowTripDetails: 'report/noshowretripportdetails',
    addSafeDropSetupV3: 'safedrop/addSetupv3',
    editSafeDropSetupV3: 'safedrop/editsetupv3',
    getAllSafeDropSetupV3: 'safedrop/getallsetupv3?page=',
    addSafeDropEscalationv3: 'safedropescalation/addSetupv3',
    editSafeDropEscalationv3: 'safedropescalation/editsetupv3',
    getAllSafeDropEscalationv3: 'safedropescalation/getallsetupv3',
    enableDisableSafeDropEsculationv3: 'safedropescalation/enabledisablesetup?id=',
    getSafeDropStopTrips: 'ivrcall/getsafedropescalationtrips',
    approveSafeDropStopTrip: 'ivrcall/approvepanicstoptrip',
    getReasonDetails: 'billingexceptions/getexceptionreason',
    getPredictionDetails: 'show_status/show_prediction_details?date=',
    getPredictionSummary: 'show_status/show_prediction_summary?date=',
    getTrendsPredictions: 'show_status/weekly_trends',
    downloadFileBehavioural:'show_status/download_excel_report?siteId=',
    getOtaSummary:'trip_ota/get_ota_summary?date=',
    getOtaDetails:'trip_ota/get_ota_details?date=',
    getOtaTrends:'trip_ota/get_weekly_trends',
    downloadFileOta:'trip_ota/download_excel_report?siteId=',
    getSafeDropIncident: 'report/getsafedropincidentreport?siteId=',
    getAllTripsForAllocation: 'tripdetails/getAllTripsForAllocation',
    getAdjustmentTrip: 'adjustmenttrip/getalladjustmenttripsv3?page=',
    uploadBulkemail: 'bulkEmail/bulkemailupload',
    nearbyLandmark: 'landmark/getnearbylandmarks?locationId=',
    siteDetailsForaddingAL: 'site/getsitebasedonsiteid?siteId=',
    ivrEmpCall: 'ivrcall/connecttososcalls?tripId=',
    showNearByVehile: 'employeepanic/getemployeepanicnearbyvehicle?tripId=',
    allowToModifyTrip: '/tripdetails/allowtomodifytrip?tripId=',
    getAllPendingSubscription: 'subscription/gettobesuscribeusers',
    getVehcileInfoByIdV3: 'vehicle/getvehicledetailsbyid?vehicleId=',
    getDriverDetailsByIdV3: 'driver/getdriverdetailsbyid?id=',
    getUploadS3ImgUrl: 'vehicle/downloadvehicleDocData?fileName=',
    getDriverUploadS3DocUrl: 'driver/downloaddriverDocData?fileName=',
    getDriverPanicbytripId: 'panicaction/getdriverpanicbytripid?tripId=',
    editDriver: 'driver/updatedriver',
    getSubscribePendinguser:'subscription/subscribependinguser?empId=',
    gettripratingreport:'report/gettripratingreport?siteId=',
    getVehicleListBySiteVendorMasterIdAndVehicleType: 'vehicle/getvehiclebyregno?siteId=',
    wightInSetup: 'vendorweightage/addsetup',
    getCapacityUtilization: 'report/getcapacityutilization?siteId=',
    addVendorMasterInfo:'vendorcontactinfo/add',
    updateVendorMasterInfo:'vendorcontactinfo/update',
    getAllVendorMasterInfo:'vendorcontactinfo/get',
    enabledisableContactInfo: 'vendorcontactinfo/enabledisable?id=',
    getVendorMasterInfoAuditById: 'audit/getvendorcontactinfoaudit',
    getDriversListWithRegistrationStatusV3: 'driver/getdriverslistwithregistrationstatus?searchKey=',
    getAdjustmentTripAudit: 'audit/getadjustmenttripaudit?id=',
    getCapacityUtilizationSummary:"report/getcapacityutilizationsummary?siteId=",
    vendorReallocationReason: 'tripdetails/getreallocationreason',
    vendorConfirmReallocation: 'tripdetails/reallocatetrip?tripId=',
    getTripReallocationtReport:'report/gettripreallocationtreport?siteId=',
    getDriverDownloadByAdmin: 'driver/getdrivers'
}
