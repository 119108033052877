import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TripWarningComponent } from './trip-warning/trip-warning.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';

@Component({
  selector: 'app-adjustment-trips',
  templateUrl: './adjustment-trips.component.html',
  styleUrls: ['./adjustment-trips.component.scss']
})
export class AdjustmentTripsComponent {

  mData: any[] = [];
  isAdjustmentTrips: boolean = true;
  _mCurrentUserData: any;
  _mEmpId: number = null;
  _mCurrentUserRoleFlag: boolean = false;
  page: number = 1;
  size: number = 100;
  _mCurrentNumber: number = 0;
  _mTotalElements: number = 0;
  _mNumberOfElements: number = 0;
  _mTotalPage: number = 1;

  dataSource = new MatTableDataSource<any>(this.mData);
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  displayedColumns = ['date', 'tripCode', 'siteName', 'vendorName', 'employeeId', 'logType', 'nearestShiftTime', 'approximateKm', 'vehicleType', 'driverName', 'raisedOn', 'actionOn', 'actionBy', 'status', 'audit'];
  activeTabId = 1;
  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
  ) { }


  ngOnInit() {
    this.getAllAdjustementTrips();
  }

  getCurrentUser() {
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    if (this._mCurrentUserData != null) {
      if (
        this._mCurrentUserData.userInfoDetails.role.id == 3 ||
        this._mCurrentUserData.userInfoDetails.role.id == 4
      ) {
        this._mCurrentUserRoleFlag = true;
      }
      this._mEmpId =
        this._mCurrentUserData.userInfoDetails.role.id == 1 ||
          this._mCurrentUserData.userInfoDetails.role.id == 2 ||
          this._mCurrentUserData.userInfoDetails.role.id == 4
          ? ""
          : this._mCurrentUserData.userInfoDetails.id;
      this.getAllAdjustementTrips();
    }
  }

  onNewPageRequest($event) {
    this.page = $event;
    this.getAllAdjustementTrips();
  }

  getAllAdjustementTrips() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTrip + (this.page - 1) + '&size=' + this.size, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let locarr: any[] = [];
        success.body.data.forEach(element => {
          locarr.push({
            "id": element.id, "date": element.date, "tripCode": element.tripCode, "siteName": element.siteName, "vendorName": element.vendorName,
            "employeeId": element.employeeId, "logType": element.logType, "nearestShiftTime": element.nearestShiftTime, "approximateKm": element.approximateKm, "status": element.status, "vehicleType": element.vehicleType, "driverName": element.driverName, "raisedOn": element.raisedOn, "actionOn": element.actionOn, "actionBy": element.actionBy
          });
        });
        this.mData = locarr;
        this.dataSource.data = locarr;
        setTimeout(() => this.dataSource.paginator = this.paginator.toArray()[0]);
        this._mCurrentNumber = success.body.pageNo;
        this._mTotalPage = success.body.totalPages;
        this.size = success.body.size;
        this._mTotalElements = success.body.totalElements;
        this._mNumberOfElements = success.body.noOfElements;
      }
      else {
        this.mData = [];
        this._mCurrentNumber = 0;
        this._mTotalElements = 0;
        this._mTotalPage = 0;
        this._mNumberOfElements = 0;
        this.size = 0;

      }
    },
      (error) => {
        this.notifyService.showError("error", null);
      })
  }

  pendingStatusRequest(showmsg, i) {
    if (
      showmsg == "SENT_FOR_VENDOR_APPROVAL" ||
      showmsg == "CANCELLED_BY_ADMIN" ||
      showmsg == "REJECTED_BY_ADMIN"
    ) {
      let msg = null;
      if (showmsg == "REJECTED_BY_ADMIN") {
        msg = "Are you sure want to Reject ?";
      } else if (showmsg == "CANCELLED_BY_ADMIN") {
        msg = "Are you sure want to Cancel ?";
      } else {
        msg = "Are you sure want to Approve ?";
      }

      const data = msg;
      const dialogRef = this.dialog.open(TripWarningComponent, {
        data: msg,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onSubmit(showmsg, i);
        }
      });
    }
  }

  onSubmit(showmsg, index) {
    let data = this.mData[index];
    data.status = showmsg;
    if (data.status == 'SENT_FOR_VENDOR_APPROVAL') {
      data.status = 'Approved';
    }
    this.ngxLoader.start();
    this.apiService.doPutRequestWithResponse(ApiConstants.updateAdjustmentTrip + data.id + '&status=' + data.status, '', (success) => {
      this.ngxLoader.stop();
      if (success.status === 200) {
        this.notifyService.showSuccess("Booking Updated Successfully.", null);
      }
      else {
        this.ngxLoader.stop();
        this.notifyService.showWarning("Error while Booking update", null);
      }
    },
      error => {
        this.ngxLoader.stop();
      }
    );

  }

  onAuditLogClicked(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTripAudit + e.id, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let data = success.body;
        let object = Object.entries(e);
        data.data.push(object[2]);
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '80vh',
          data: data,
          disableClose: true
        });
      } else {
        this.notifyService.showInfo("No audit logs found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showWarning("No audit logs found.", null);
    });
  }
  
}




