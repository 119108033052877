import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs/internal/observable/of';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import Swal from 'sweetalert2';
import { ShowCategoryComponent } from '../trip-mirror/show-category/show-category.component';
import { ExceptionUsersComponent } from './exception-users/exception-users.component';
import { MissingEmployeeExceptionComponent } from './missing-employee-exception/missing-employee-exception.component';
import { TravelStatusComponent } from './travel-status/travel-status.component';
import { TripMapComponent } from './trip-map/trip-map.component';
import { TripUserTimeComponent } from './trip-user-time/trip-user-time.component';
import { UploadTripStatusComponent } from './upload-trip-status/upload-trip-status.component';
import { auto } from '@popperjs/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-trip-view',
  templateUrl: './trip-view.component.html',
  styleUrls: ['./trip-view.component.scss']
})
export class TripViewComponent implements OnInit {
  arrayBuffer: any;
  file: File;
  _mTitle: string = "Trip View";
  address: any = false;
  areaLandmark: any = true;
  sub: any;
  _mReturnUrl: any;
  siteid: number;
  shiftid: number;
  date: any;
  tripsheet: any;
  tripViewList: any[] = [];
  checkedTrips: any = [];
  exceldata: any = [];
  siteData: any[] = [];
  tripModifyFile: string;
  tripSummary: any;
  missingUsers: any[] = [];
  tripUsersId: any[] = [];
  tempTripUsersId: any[] = [];
  exceptionEmployee: any[] = [];
  checkedFlag: boolean = false;
  routingType: any;
  searchTrip: any;
  displayedRows$: any[] = [];
  mapType: any;
  tripdetails: any = [];
  escortId: any;
  escort: any = [];
  escortFlag: boolean = false;
  seatsOccupiedPercentFlag: boolean = false;
  showHideAddress: boolean = false;
  showContact: boolean = false;
  maskingEnabled = false;
  roleName: any;
  allExpandState: any;
  tempTripId: any;
  mTravelTime: any;
  routingConfigTime: any;
  travelTimeFlag: boolean;
  _mCurrentUser: any;
  domainName: any;
  editFlag: boolean = true;
  fixedRouting: boolean = false;
  adminTrackTrips: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly apiService: ApiService,
    private serviceExportExcel: ServiceExportExcel,
    private serviceLocalStorage: ServiceLocalStorage,
    private serviceSharedData: ServiceSharedData,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private dialog: MatDialog,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.getRolePermissions();
    this.roleName = this.serviceLocalStorage.getJsonValue("role-name");
    this._mCurrentUser = this.serviceLocalStorage.getUserObject();
    if (this.serviceLocalStorage.getRoleName() == 'Admin' || this.serviceLocalStorage.getRoleName() == 'Super Admin' || this.serviceLocalStorage.getRoleName() == 'Site Admin') {
      this.travelTimeFlag = true;
    } else {
      this.travelTimeFlag = false;
    }
    this.checkMaskingEnabled();
    this.sub = this.route.params.subscribe(params => {
      this.siteid = params["siteId"];
      this.shiftid = params["shiftId"];
      this.date = params["date"];
    });
    this.mapType = this.serviceLocalStorage.getMapType();
    this.getAllEscortBasedOnSiteId(this.siteid);
    if (this._mCurrentUser.id == this.cookieService.get('selectUserID')) {
      this.address = this.cookieService.get('addressToggle');
      this.areaLandmark = false;
    } else {
      this.cookieService.delete('selectUserID');
      this.cookieService.delete('addressToggle');
    }
  }

  getRolePermissions() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getRolePermissions + parseInt(this.serviceLocalStorage.getRoleId()), success => {
      if (success.status == 200) {
        this.adminTrackTrips = success.body.adminTrackTrips;
      }
    }, error => {
    });
  }

  getAllEscortBasedOnSiteId(siteid: number) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getEscortListBySiteId + siteid, success => {
      if (success) {
        this.escort = success.body;
      }
    }, error => {
      console.log(error)
    });
  }

  checkMaskingEnabled() {
    let clientCode = localStorage.getItem("clientCode")
    this.apiService.doGetRequestWithResponse(ApiConstants.checkMaskingEnabled + clientCode, success => {
      if (success.status === 200) {
        this.maskingEnabled = success.body.maskingEnabled;
      }
      if (!this.maskingEnabled) {
        this.showHideAddress = true;
        this.showContact = true;
      }
    }, error => {
      if (!this.maskingEnabled) {
        this.showHideAddress = true;
        this.showContact = true;
      }
    })
  }

  sortData(sort: Sort) {
    const data = this.tripViewList.slice();
    if (!sort.active || sort.direction === '') {
      this.displayedRows$ = data;
      return;
    }
    this.displayedRows$ = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'tripCode': return this.compare(a.tripCode, b.tripCode, isAsc);
        case 'vehicleNo': return this.compare(a.vehicleNo, b.vehicleNo, isAsc);
        case 'vendor': return this.compare(a.Vendor, b.Vendor, isAsc);
        case 'driver': return this.compare(a.Driver, b.Driver, isAsc);
        case 'vehicleType': return this.compare(a.vehicleType, b.vehicleType, isAsc);
        case 'zoneName': return this.compare(a.zone, b.zone, isAsc);
        case 'routeName': return this.compare(a.routeName, b.routeName, isAsc);
        case 'area': return this.compare(a.pickupDropPoint, b.pickupDropPoint, isAsc);
        case 'escort': return this.compare(a.escort, b.escort, isAsc);
        case 'distance': return this.compare(a.distance, b.distance, isAsc);
        case 'distanceCovered': return this.compare(a.distanceCovered, b.distanceCovered, isAsc);
        case 'seatsOccupiedPercent': return this.compare(Number(a.seatsOccupiedPercent), Number(b.seatsOccupiedPercent), isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadData();
    });
  }

  loadData() {
    this.tripViewList = [];
    this.displayedRows$ = [];
    this.getSites();
    this.getSummary();
    this.getRoutingConfigBySiteId();
    const rows$ = of(this.tripViewList);
    this.displayedRows$ = this.tripViewList;
  }

  getSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveSitesbyUser, success => {
      this.siteData = success.body.filter(
        site => site.siteId == this.siteid
      );
    }, error => {
      console.log(error)
    });
  }

  getSummary() {
    this.apiService.doGetRequestWithResponse(ApiConstants.fetchTripSummary + this.siteid + '&shiftid=' + this.shiftid + '&date=' + this.date, success => {
      this.tripSummary = success.body;
      this.tripValidation();
      this.getTripSheet();
    }, error => {
      console.log(error)
    });
  }

  updateEmployeeTravelTime(emp) {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.updateEmployeePickUpTime + this.siteid + '&tripUserId=' + emp.tripUserId + '&tripTravelTime=' + emp.time, '', success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.notifyService.showSuccess("Updated successfully", null);
      } else {
        this.notifyService.showWarning("Update Failed. Please check Routing Config ", null);
      }
    }, error => {
      this.ngxLoader.stop();
      console.log(error)
    })
  }

  onTimeValidate(value, emp) {
    emp.time = value;
  }

  omit_special_char_And_String1(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k == 58 || (k >= 48 && k <= 57));
  }

  checkAll(event) {
    this.checkedTrips = [];
    if (event.checked) {
      this.tripViewList.forEach(trip => {
        this.checkedTrips.push(trip.tripid);
      });
      this.checkedFlag = true;
    } else {
      this.tripViewList.forEach(trip => {
        let index: number = this.checkedTrips.indexOf(trip.tripid);
        if (index !== -1) {
          this.checkedTrips.splice(index, 1);
        }
      });
      this.checkedFlag = false;
    }
  }

  showOrHideAddress(e) {
    if (e) {
      this.areaLandmark = true;
      this.address = false;
      this.cookieService.delete('selectUserID');
      this.cookieService.delete('addressToggle');
    } else {
      this.address = true;
      this.areaLandmark = false;
      this.cookieService.set('selectUserID', this._mCurrentUser.id, 7, '/', this.domainName, true, 'Strict');
      this.cookieService.set('addressToggle', this.address, 7, '/', this.domainName, true, 'Strict');
    }
  }

  showOrHideContact(e) {
    if (e) {
      this.showContact = true;
    } else {
      this.showContact = false;
    }
  }

  allocateVendor() {
    if (this.checkedTrips.length < 1) {
      this.notifyService.showWarning("Select atleast one trip to assign vendor", null);
    } else {
      this.serviceSharedData.setData("trips", this.checkedTrips);
      this.router.navigate(["/myatom/routing/allocatevendor", this.siteid, this.shiftid, this.date]);
    }
  }

  onClickBack() {
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.tripViewFlag, true);
    this.router.navigate(["/myatom/routing/trips"]);
  }

  tripValidation() {
    let shiftTime = this.tripSummary.SHIFT.split(" ");
    let shiftValue = shiftTime[0].split(":");
    const logggedInTime = new Date();
    let tripDate = this.date.split("/");
    let tripDateFinal: any = tripDate[1] + "/" + tripDate[0] + "/" + tripDate[2];
    let shiftTimeDate = new Date(tripDateFinal);
    var tomorrowsDate = new Date(shiftTimeDate.setHours(0, 0, 0, 0) + 86400000);
    tomorrowsDate.setHours(23);
    tomorrowsDate.setMinutes(59);
    tomorrowsDate.setSeconds(59);
    if (shiftValue[0] >= 12) {
      if (tomorrowsDate < logggedInTime) {
        this.editFlag = false;
        this.notifyService.showInfo("Past Trips Cannot be Edited", null);
      }
    }
    else {
      shiftTimeDate.setHours(23);
      shiftTimeDate.setMinutes(59);
      shiftTimeDate.setSeconds(59);
      if (shiftTimeDate < logggedInTime) {
        this.editFlag = false;
        this.notifyService.showInfo("Past Trips Cannot be Edited", null);
      }
    }
  }

  getTripSheet() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getTripSheet + this.siteid + '&shiftid=' + this.shiftid + '&date=' + this.date, success => {
      if (success.status == 200) {
        this.tripsheet = success.body;
        this.routingType = success.body[0].tripDetails.routingType
        this.ngxLoader.stop();
        this.tripSummary.SECURITY = 0;
        this.tripsheet.forEach(element => {
          let tripemp: any = [];
          if (element.tripDetails.escortStatus) {
            this.tripSummary.SECURITY = this.tripSummary.SECURITY + 1;
          }
          element.tripDetails.tripUsers.forEach(tripuser => {
            let vflag = false;
            if (tripuser.landmark.type == "C") this.fixedRouting = true;
            tripemp.push({
              tripUserId: tripuser.id,
              employeeId: tripuser.tripUser.userInfoDetails.employeeId == null ? "" : tripuser.tripUser.userInfoDetails.employeeId,
              employeeName: tripuser.tripUser.userInfoDetails.displayName == null ? "" : tripuser.tripUser.userInfoDetails.displayName,
              gender: tripuser.tripUser.userInfoDetails.gender == null ? "NA" : tripuser.tripUser.userInfoDetails.gender == 'Male' ? 'M' : tripuser.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O',
              userid: tripuser.tripUser.id,
              bookingid: tripuser.booking ? tripuser.booking.bookingId : null,
              // address: tripuser.landmark.type == "H" ? tripuser.landmark.area.area + ", " + tripuser.landmark.landmark : tripuser.tripUser.userInfoDetails.address == null ? "NA" : tripuser.tripUser.userInfoDetails.address,
              address: tripuser.landmark.type == "H" ? tripuser.landmark.landmark : (tripuser.userLandmark !== null ? tripuser.userLandmark.area.area + ", " + tripuser.userLandmark.landmark : (tripuser.tripUser.userInfoDetails.address == null ? "NA" : tripuser.tripUser.userInfoDetails.address)),
              distance: tripuser.empTripDistance == null ? "0" : tripuser.empTripDistance,
              time: tripuser.pickupDropTime ? tripuser.pickupDropTime.slice(0, -3) : "00:00",
              userColor: tripuser.tripUser.userInfoDetails.userCategory[0] == null ? "#006aa8" : tripuser.tripUser.userInfoDetails.userCategory[0].categories.colorCode,
              medical: vflag,
              showstatus: tripuser.showStatus == null ? "NO SHOW" : tripuser.showStatus,
              contact: tripuser.tripUser.userInfoDetails.contactNumber == null ? "NA" : tripuser.tripUser.userInfoDetails.contactNumber,
              adtime: element.tripDetails.shift.logType == "IN" ? tripuser.boardedDateTime : tripuser.arrivalDateTime,
              checkInTime: tripuser.checkInTime,
              checkOutTime: tripuser.checkOutTime,
              outOfSiteBoundry: tripuser.landmark.isOutOfSiteBoundary == null ? null : tripuser.landmark.isOutOfSiteBoundary,
              // area: tripuser.landmark.type == "C" ? (tripuser.landmark.area != null ? tripuser.landmark.area.area : "NA") : (tripuser.tripUser.userInfoDetails.pickUpDrop == null ? "NA" : tripuser.tripUser.userInfoDetails.pickUpDrop.area.area),
              // landMark: tripuser.landmark.type == "C" ? tripuser.landmark.landmark : tripuser.tripUser.userInfoDetails.pickUpDrop == null ? "NA" : tripuser.tripUser.userInfoDetails.pickUpDrop.landmark,
              area: tripuser.landmark.type == "C" ? (tripuser.landmark.area !== null ? tripuser.landmark.area.area : "NA") : tripuser.userLandmark !== null ? (tripuser.userLandmark.area != null ? tripuser.userLandmark.area.area : "NA") : tripuser.tripUser.userInfoDetails.pickUpDrop == null ? "NA" : (tripuser.tripUser.userInfoDetails.pickUpDrop.area != null ? tripuser.tripUser.userInfoDetails.pickUpDrop.area.area : "NA"),
              landMark: tripuser.landmark.type == "C" ? tripuser.landmark.landmark : tripuser.userLandmark !== null ? tripuser.userLandmark.landmark : tripuser.tripUser.userInfoDetails.pickUpDrop == null ? "NA" : tripuser.tripUser.userInfoDetails.pickUpDrop.landmark,
              smsStatus: tripuser.smsStatus
            });
          });
          this.tripViewList.push({
            tripid: element.tripDetails.tripId == null ? "" : element.tripDetails.tripId,
            fullTripCode: element.tripDetails.fullTripCode == null ? "NA" : element.tripDetails.fullTripCode,
            tripCode: element.tripDetails.tripCode == null ? "NA" : element.tripDetails.tripCode,
            mtripCode: element.tripDetails.tripCode == null ? "NA" : element.tripDetails.tripCode.slice(0, 4) + element.tripDetails.tripCode.slice(-4),
            tripstatus: element.tripDetails.tripstatus == null ? "" : element.tripDetails.tripstatus,
            vehicleNo: element.vehicle == null ? "NA" : element.vehicle.registrationNo,
            Vendor: element.vendorMaster == null ? "NA" : element.vendorMaster.name,
            Driver: element.driver == null ? "NA" : element.driver.name,
            Number: element.driver == null ? "NA" : element.driver.contactNumber,
            pickupDropPoint: element.tripDetails.shift.logType == "IN" ? this.routingType == "ALGORITHM"
              ? element.tripDetails.tripUsers[0].tripUser.userInfoDetails.address : element.tripDetails.tripUsers[0].tripUser.userInfoDetails.pickUpDrop.area == null
                ? "NA" : element.tripDetails.tripUsers[0].tripUser.userInfoDetails.pickUpDrop.area.area : this.routingType == "ALGORITHM"
              ? element.tripDetails.tripUsers[tripemp.length - 1].tripUser.userInfoDetails.address
              : element.tripDetails.tripUsers[tripemp.length - 1].tripUser.userInfoDetails.pickUpDrop.area == null
                ? "NA" : element.tripDetails.tripUsers[tripemp.length - 1].tripUser.userInfoDetails.pickUpDrop.area.area,
            deviceTripStatus: element.DeviceTripStatus == null ? "" : element.DeviceTripStatus,
            zone: element.tripDetails.zone == null ? 'NA' : element.tripDetails.zone.name,
            routeName: element.tripDetails.routeName == null ? 'NA' : element.tripDetails.routeName,
            masterRouteName: element.tripDetails.masterRouteName,
            vehicleType: element.vehicleType.vehicleType,
            escort: element.tripDetails.escortStatus == false ? "NO" : "YES",
            distance: element.tripDetails.tripDistance == null ? "0" : element.tripDetails.tripDistance,
            distanceCovered: element.distanceCovered == null ? "0" : element.distanceCovered,
            seatCapacity: element.vehicleType.seatCapacity,
            seatsOccupied: tripemp.length == null ? "0" : tripemp.length + (element.tripDetails.escortStatus ? 1 : 0),
            tripemp: tripemp,
            escortname: element.escort == null ? "NA" : element.escort.name,
            alloacateVehicle: element.vehicle == null ? "NA" : element.vehicle.vehicleType.vehicleType,
            triptraveltime: element.tripDetails.tripTravelTime,
            shift: element.tripDetails.shift.shiftTime + " " + element.tripDetails.shift.logType,
            logType: element.tripDetails.shift.logType,
            date: element.tripDetails.tripDate.split(" ")[0],
            tripDate: element.tripDetails.tripDate,
            seatsOccupiedPercent: (element.tripDetails.escortStatus ? (((tripemp.length + 1) / element.vehicleType.seatCapacity) * 100)
              : ((tripemp.length / element.vehicleType.seatCapacity) * 100)).toFixed(0),
            tatd: element.startDateTime == null ? "" : element.startDateTime,
            stopTime: element.stopDateTime == null ? "" : element.stopDateTime
          });
        });
      } else {
        this.ngxLoader.stop();
        this.notifyService.showInfo("No Trips Found", null);
        this.router.navigate(["/myatom/routing/trips"]);
      }
    }, error => {
      this.ngxLoader.stop();
      this.notifyService.showInfo("No Trips Found", null);
    });
  }

  mapview(tripid) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.gettripbytripid + '?tripid=' + tripid, success => {
      let reqData = {
        params: {
          siteId: this.siteid,
          shiftId: this.shiftid,
          date: this.date,
          tripEditFlag: this.editFlag
        },
        data: success.body,
        mapType: this.mapType
      };
      this.dialog.open(TripMapComponent, {
        width: '1250px',
        disableClose: true,
        data: reqData
      });
      this.ngxLoader.stop();
    }, error => {
      this.notifyService.showInfo("No Trips Found", null);
      this.ngxLoader.stop();
    });
  }

  getTripDetails(tripids) {
    let driverstatusflag = true;
    let locTripId = 0;
    let locDriverStatus = "";
    let tripsIds = tripids.map(ele => { return 'tripids=' + ele; }).join('&');
    this.apiService.doPostRequestWithResponse(ApiConstants.gettripsbytripids1 + tripsIds, '', success => {
      if (success.status == 200) {
        this.tripdetails = success.body[0];
        this.tripdetails.forEach(trip => {
          if (trip.DeviceTripStatus != "not started") {
            driverstatusflag = false;
            locTripId = trip.tripDetails.tripCode;
            locDriverStatus = trip.DeviceTripStatus;
          }
        });
        if (driverstatusflag) {
          this.serviceSharedData.setData("trips", this.checkedTrips);
          this.router.navigate(["/myatom/routing/tripmodify", this.siteid, this.shiftid, this.date]);
        } else {
          this.notifyService.showWarning(locTripId + " can't modify.trip is already " + locDriverStatus, null);
        }
      }
    }, error => {
      this.ngxLoader.stop();
      this.notifyService.showError(error.message, null);
    });
  }

  modifyTrip() {
    if (this.checkedTrips.length < 1) {
      this.notifyService.showWarning("Select atleast one trip to modify", null);
    } else {
      this.allowToModifyTrip(this.checkedTrips);
      // this.getTripDetails(this.checkedTrips);
    }
  }

  allowToModifyTrip(tripids) {
    this.apiService.doGetRequestWithResponse(ApiConstants.allowToModifyTrip + tripids[0], success => {
      if (success.body.allowToModify) {
        this.getTripDetails(this.checkedTrips);
      } else {
        this.notifyService.showWarning(success.body.message, null);
      }
    }, error => {
      this.ngxLoader.stop();
      this.notifyService.showError(error.message, null);
    });
  }

  deleteTrip() {
    if (this.checkedTrips.length < 1) {
      this.notifyService.showInfo("Select atleast one trip to delete", null);
    } else {
      let msg = "Are you sure want to delete the selected Trips?";
      let msgKey = AppConstants.DELETE_MSG;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ms-2'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: msg,
        text: msgKey,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
      }).then(result => {
        if (result.value) {
          swalWithBootstrapButtons.fire('Done', msgKey, 'success');
          this.ngxLoader.start();
          this.apiService.doPostRequestWithResponse(ApiConstants.deletetrip, this.checkedTrips, success => {
            this.ngxLoader.stop();
            if (success.status == 202) {
              this.checkAll(false);
              this.checkedTrips = [];
              this.loadData();
              this.notifyService.showSuccess(success.body.message, null);
            }
          }, error => {
            this.ngxLoader.stop();
          });
        } else {
          swalWithBootstrapButtons.fire('Cancelled', 'Not Deleted', 'error');
          this.loadData();
        }
      });
    }
  }

  checkTrip(event, tripid) {
    if (event.checked) {
      this.checkedTrips.push(tripid);
    } else {
      let index: number = this.checkedTrips.indexOf(tripid);
      if (index !== -1) {
        this.checkedTrips.splice(index, 1);
      }
    }
  }

  showCategory(): void {
    let dialogRef = this.dialog.open(ShowCategoryComponent, {
      data: "hello",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  downloadAL(): void {
    this.exceldata = [];
    let data: any = {};
    let number = 1;
    this.tripViewList.forEach(element => {
      this.exceldata.push({
        tripCode: element.fullTripCode,
        vehicleNo: element.vehicleNo,
        vendor: element.Vendor,
        driver: element.Driver,
        contactNo: element.Number,
        location: element.pickupDropPoint,
        vehicleType: element.vehicleType,
        escortRequired: element.escort,
        escortName: element.escortname,
        planneDistance: element.distance,
        distanceCovered: element.distanceCovered,
        seatCapacity: element.seatCapacity,
        seatOccupied: element.seatsOccupied,
        allocateVehicle: element.alloacateVehicle,
        tripTravelTime: element.triptraveltime,
        site: this.siteData[0].siteName,
        shift: element.shift,
        date: element.date,
        seatOccupiedPercent: element.seatsOccupiedPercent
      });
      this.exceldata.push({
        tripCode: "EmployeeId",
        vehicleNo: "EmployeeName",
        vendor: "Gender",
        driver: "Distance",
        contactNo: "Time",
        location: "Medical",
        vehicleType: "TravelStatus",
        escortRequired: "Contact",
        escortName: (this.routingType === 'FIXED') ? 'RouteName' : 'ZoneName',
        distance: 'Address',
        seatCapacity: 'Area'
      });
      element.tripemp.forEach(element1 => {
        this.exceldata.push({
          tripCode: element1.employeeId,
          vehicleNo: element1.employeeName,
          vendor: element1.gender,
          driver: element1.distance,
          contactNo: element1.time,
          location: element1.medical,
          vehicleType: element1.showstatus,
          escortRequired: element1.contact,
          escortName: (element.masterRouteName != null) ? '(M)' + element.masterRouteName : (this.routingType === 'FIXED') ? element.routeName : element.zone,
          distance: element1.address,
          seatCapacity: element1.area
        });
      });
      if (number < this.tripViewList.length) {
        this.exceldata.push({}, {
          tripCode: "tripCode",
          vehicleNo: "vehicleNo",
          vendor: "vendor",
          driver: "driver",
          contactNo: "contactNo",
          location: "location",
          vehicleType: "vehicleType",
          escortRequired: "escortRequired",
          escortName: "escortName",
          distance: "distance",
          seatCapacity: "seatCapacity",
          seatOccupied: "seatOccupied",
          allocateVehicle: "allocateVehicle",
          tripTravelTime: "tripTravelTime",
          site: "site",
          shift: "shift",
          date: "date",
          seatOccupiedPercent: "seatOccupiedPercent"
        });
      }
      number++;
    });
    this.serviceExportExcel.exportAsExcelFileForTrips(this.exceldata, "Trips");
  }

  onFileChange($event) {
    this.file = $event.target.files[0];
    this.checkExceptionEmployee($event);
    this.tempTripUsersId = [];
    this.tripViewList.forEach(element => {
      element.tripemp.forEach(user => {
        const data = {
          EmployeeId: user.employeeId,
          Name: user.employeeName,
          ContactNo: user.contact,
          Gender: user.gender,
          Address: user.address
        };
        this.tempTripUsersId.push(data);
      });
    });
  }

  checkExceptionEmployee($event) {
    this.missingUsers = [];
    let fileReader = new FileReader();
    fileReader.onload = e => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      // var workbook = XLSX.read(bstr, { type: "binary" });
      // var first_sheet_name = workbook.SheetNames[0];
      // var worksheet = workbook.Sheets[first_sheet_name];
      // this.missingUsers = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.tripUsersId = [];
      this.missingUsers.forEach(element => {
        this.tripUsersId.push(element.tripCode);
      });

      this.exceptionEmployee = [];
      this.tempTripUsersId.forEach(element => {
        if (this.tripUsersId.includes(element.EmployeeId)) {
        } else {
          this.exceptionEmployee.push(element);
        }
      });
      if (this.exceptionEmployee.length > 0) {
        let dialogRef = this.dialog.open(MissingEmployeeExceptionComponent, {
          data: this.exceptionEmployee,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == "Allow") {
            this.upload($event);
          } else if (result == "Deny") {
            this.tripModifyFile = "";
          }
        });
      } else {
        this.upload($event);
      }
    };
    fileReader.readAsArrayBuffer(this.file);
  }

  upload($event) {
    let dialogRef = this.dialog.open(TripUserTimeComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let type = null;
        if (result == "GoogleTime") {
          type = "TRIP_BATCH_GOOGLE_TIME";
        } else if (result == "ManualTime") {
          type = "TRIP_BATCH_MANUAL_TIME";
        }
        this.ngxLoader.stop();
        let files = $event.target.files;
        if (files && files.length > 0) {
          let fileName: string = files[0].name;
          let fileExt = fileName.split(".").pop();
          if (fileExt) {
            if (fileExt.includes("xlsx") || fileExt.includes("xls")) {
              let userId: any = parseInt(this.serviceLocalStorage.getUserId().toString());
              let formdata: FormData = new FormData();
              formdata.append('file', files[0]);
              formdata.append('type', type);
              formdata.append('userid', userId);
              this.apiService.doPostFile(ApiConstants.upload, formdata, success => {
                this.ngxLoader.stop();
                this.uploadTripStatus(success.body);
              }, error => {
                this.ngxLoader.stop();
                this.notifyService.showError("Error occured", null);
              });
            } else {
              this.ngxLoader.stop();
              this.notifyService.showInfo("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
            }
            this.tripModifyFile = "";
          } else {
            this.ngxLoader.stop();
            this.notifyService.showInfo("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
          }
        }
      } else {
        this.tripModifyFile = "";
      }
    });
  }

  onShowStatusEdit(emp, trip) {
    if (emp.showstatus == 'NO SHOW') {
      let empId = emp.userid;
      let tripId = trip.tripid;
      let dialogRef = this.dialog.open(TravelStatusComponent, {
        width: '485px',
        data: { empId, tripId },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ngOnInit();
          this.ngAfterViewInit();
        }
      });
    } else {
      this.notifyService.showInfo("Not Allowed", null);
    }
  }

  uploadTripStatus(tripData) {
    let dialogRef = this.dialog.open(UploadTripStatusComponent, {
      data: tripData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  showExceptionEmployees() {
    let data = { date: this.date, siteid: this.siteid, shiftid: this.shiftid };
    let dialogRef = this.dialog.open(ExceptionUsersComponent, {
      data: data,
      // width: "700px",
      // minHeight:"500px",
      // height: auto,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  percentage4BgColor(p) {
    if (p <= 50) {
      return "#FF455B";
    }
    if (p > 50 && p < 100) {
      return "#2EBCF0";
    }
    if (p == 100) {
      return "#43DF94";
    }
  }

  modifiedColor(p) {
    if (p.tripstatus == "MODIFIED") {
      return "#0056FD"; // Blue
    }
    for (let id in p.tripemp) {
      if (p.tripemp[id].outOfSiteBoundry != null && p.tripemp[id].outOfSiteBoundry) {
        return '#FF455B'; // red
      }
    }
    if (p.tripstatus == "VENDOR_PUSHBACKED") {
      return "#9CA3AF"; //Grey
    }
    else {
      return '#FFA450'; // orange
    }
  }


  modifiedBgColor(p) {
    if (p.tripstatus == "MODIFIED") {
      return "#F2F6FF"; // light blue
    } else if (p.tripstatus == "VENDOR_PUSHBACKED") {
      return "#F1F1F1"; // light grey
    } else {
      return '#FFF6F1'; // light orange
    }
  }

  getAudit(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getTripViewAuditById + '?id=' + e.tripid, success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let data = success.body;
        let object = Object.entries(e);
        data.data.push(object[2]);
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '80vh',
          data: data,
          disableClose: true
        });
      } else {
        this.notifyService.showInfo("No audit logs found", null);
      }
    }, error => {
      this.ngxLoader.stop();
    });
  }

  addingEscortInTrip() {
    this.escortFlag = true;
  }

  onChangeForEscort(event, tripid, p, v, d) {
    if (v == "NA" && d == "NA") {
      return this.notifyService.showWarning("Please allocate vehicle and driver to trip", null);
    }
    if (p >= 100) {
      return this.notifyService.showWarning("All Seats Occupied So Please Change VehicleType", null);
    }
    var escortId = event.value;
    this.escortAddingTotrip(tripid, escortId);
  }

  escortAddingTotrip(tripId: number, escortId: number) {
    this.apiService.doPostRequestWithResponse(ApiConstants.addingEscortToTrip + '?tripId=' + tripId + '&escortId=' + escortId, '', success => {
      if (success.status == 200) {
        this.notifyService.showSuccess(success.body.message, null);
      }
      this.loadData();
    }, error => {
      this.notifyService.showError(error.message, null);
    });
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57));
  }

  changeText(event, item) {
    this.mTravelTime = event.target.value;
    this.tempTripId = item.tripId;
  }

  getIndex(item) {
    return this.displayedRows$.indexOf(item);
  }

  updateTravelTime(item, rowIndex) {
    if (this.mTravelTime > 0) {
      if (this.mTravelTime < this.routingConfigTime) {
        if (item.tripId == this.tempTripId && this.mTravelTime > 0) {
          let totaEmpTimeDiff;
          if (item.tripTravelTime > this.mTravelTime) {
            totaEmpTimeDiff = item.tripTravelTime - this.mTravelTime
          } else {
            totaEmpTimeDiff = this.mTravelTime - item.tripTravelTime;
          }
          let employeeTravelTimeList = [];
          employeeTravelTimeList.push({ "id": item.tripemp[0].tripUserId, "pickupDropTime": this.convertMinsToHrsMins((parseInt(item.shift.slice(0, 2)) * 60 + parseInt(item.shift.slice(3, 5)) - this.mTravelTime)) });
          item.tripemp.forEach((element, i) => {
            if (element.time != null) {
              if (i != 0) {
                let empTravelTime;
                if (item.tripTravelTime > this.mTravelTime) {
                  let averageEmpTravelTime;
                  if (totaEmpTimeDiff > 0) {
                    averageEmpTravelTime = ((item.tripTravelTime - this.mTravelTime) / item.tripemp.length) + 0.5;
                  } else {
                    averageEmpTravelTime = 0;
                  }
                  empTravelTime = this.convertMinsToHrsMins((parseInt(element.time.slice(0, 2)) * 60 + parseInt(element.time.slice(3, 5))) + Math.round(averageEmpTravelTime));
                  totaEmpTimeDiff = totaEmpTimeDiff - Math.round(averageEmpTravelTime);
                } else {
                  let averageEmpTravelTime;
                  if (totaEmpTimeDiff > 0) {
                    averageEmpTravelTime = ((this.mTravelTime.value - item.tripTravelTime) / item.tripemp.length) + 0.5;
                  } else {
                    averageEmpTravelTime = 0;
                  }
                  empTravelTime = this.convertMinsToHrsMins((parseInt(element.time.slice(0, 2)) * 60 + parseInt(element.time.slice(3, 5))) - Math.round(averageEmpTravelTime));
                  totaEmpTimeDiff = totaEmpTimeDiff - Math.round(averageEmpTravelTime);
                }
                employeeTravelTimeList.push({ "id": element.tripUserId, "pickupDropTime": empTravelTime });
              }
            }
          });
          item.tripemp.forEach((tripUser, i) => {
            let timeTaken: number = 0;
            let st: any = this.displayedRows$[rowIndex].shift.slice(0, 6);
            let travel_time: number = this.convertMinsToHrsMins((parseInt(item.shift.slice(0, 2)) * 60 + parseInt(item.shift.slice(3, 5)) - this.mTravelTime));
            let td: any = this.displayedRows$[rowIndex].distance;
            let tt: any = this.displayedRows$[rowIndex].triptraveltime;
            let avg_speed: number = (td / (this.mTravelTime)) * 60;
            (this.displayedRows$[rowIndex]);
            let dist: any = (this.displayedRows$[rowIndex].tripemp[i].distance);
            if (i == 0) {
              this.displayedRows$[rowIndex].tripemp[i].time = this.convertMinsToHrsMins((parseInt(this.displayedRows$[rowIndex].shift.slice(0, 2)) * 60 + parseInt(this.displayedRows$[rowIndex].shift.slice(3, 5)) - this.mTravelTime));
            } else {
              timeTaken = ((this.displayedRows$[rowIndex].tripemp[i].distance) / (avg_speed)) * 60;
              this.displayedRows$[rowIndex].tripemp[i].time = this.convertMinsToHrsMins((parseInt(this.displayedRows$[rowIndex].tripemp[i - 1].time.slice(0, 2)) * 60 + parseInt(this.displayedRows$[rowIndex].tripemp[i - 1].time.slice(3, 5)) + Math.round(timeTaken)));
            }
          });
          this.displayedRows$[rowIndex].tripTravelTime = (this.mTravelTime).toString();
          let updatedList = [];
          item.tripemp.forEach((tripUser, i) => {
            updatedList.push({ "id": this.displayedRows$[rowIndex].tripemp[i].tripUserId, "pickupDropTime": (this.displayedRows$[rowIndex].tripemp[i].time) });
          });
          this.ngxLoader.start();
          this.apiService.doPutRequestWithResponse(ApiConstants.updateTripTravelTime + '?tripid=' + item.tripid +
            '&triptraveltime=' + this.mTravelTime + '&siteId=' + this.siteid, updatedList, (success) => {
              this.ngxLoader.stop();
              if (success.status == 200) {
                this.notifyService.showSuccess("Successfully updated Travel time.", null);
              }
            }, (error) => {
              this.ngxLoader.stop();
            })
        } else if (this.mTravelTime == null) {
          this.notifyService.showInfo("Please enter travel time....", null);
        }
      } else {
        this.notifyService.showInfo("Travel time Should be less than routing config time(" + this.routingConfigTime + ")", null);
      }
    } else {
      this.notifyService.showWarning("Invalid travel time....", null);
    }
  }

  convertMinsToHrsMins(minutes): any {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    let hh = h < 10 ? '0' + h : h;
    let mm = m < 10 ? '0' + m : m;
    return hh + ':' + mm + ':00';
  }

  getRoutingConfigBySiteId() {
    let data = { siteid: this.siteid, shiftid: this.shiftid };
    this.apiService.doGetRequestWithResponse(ApiConstants.getRoutingConfigBySiteId + '?siteId=' + data.siteid + '&shiftTimeId=' + data.shiftid, (success) => {
      if (success.status == 200) {
        this.routingConfigTime = success.body.time;
      }
    }, (error) => {
      console.log(error)
    })
  }
  tripMerge() {
    let tripone: any, tripTwo: any, autoSequence: boolean = true;
    if (this.checkedTrips.length < 1) {
      this.notifyService.showWarning("Please select two trips", null);
      return;
    }
    if (this.checkedTrips.length > 2 || this.checkedTrips.length == 1) {
      this.notifyService.showWarning("Cannot select more than two trips", null);
      return;
    }
    tripone = this.checkedTrips[0];
    tripTwo = this.checkedTrips[1];
    this.apiService.doPostRequestWithResponse(ApiConstants.getMergeTrip + "?tripId1=" + tripone + "&tripId2=" + tripTwo + "&autoSequence=" + autoSequence, " ",
      success => {
        if (success.status == 200) {
          if (success.body.message == "Trips merged successfully") {
            this.notifyService.showSuccess(success.body.message, null);
            this.checkedTrips = [];
            this.loadData();
          } else {
            this.notifyService.showWarning(success.body.message, null);

          }
        }
      }, error => {
        console.log(error)
      })

  }

  vehicleAllocation() {
    let date = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.date);
    let logType = this.tripSummary.SHIFT.split(" ")[1];
    this.router.navigate(["/myatom/routing/assignvehicle", this.siteid, this.shiftid, this.date, logType]);
  }

}

