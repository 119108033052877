import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../constants/appconstants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  public value: BehaviorSubject<any> = new BehaviorSubject(false);
  public profileImageUrl: BehaviorSubject<any> = new BehaviorSubject('');
  private dataToUser$ = new Subject();
  dataToContainer$: any;

  constructor(public http: HttpClient) { }

  addHostToUrl(url: string): string {
    return `${environment.baseUrl}${url}`;
  }

  addHostToUrlNia(url: string): string {
    return `${environment.baseUrlNia}${url}`;
  }

  addBillingHostToUrl(url: string): string {
    return `${environment.baseUrlBilling}${url}`;
  }

  addLiveTrackingHostToUrl(url: string): string {
    return `${environment.liveTrakingBaseURL}${url}`;
  }

  updateDataToContainer(data: boolean) {
    this.dataToContainer$.next(data);
  }

  getDataUser() {
    return this.dataToUser$;
  }

  changeFilter(message: any) {
    this.filterSource.next(message);
  }

  private filterSource = new BehaviorSubject({});
  currentFilter = this.filterSource.asObservable();

  private siteSource = new BehaviorSubject(0);
  siteID = this.siteSource.asObservable();

  public mapKey: BehaviorSubject<any> = new BehaviorSubject(false);
  getMapKeyObs(): Observable<any> {
    return this.mapKey.asObservable();
  }

  setMapKeyObs(mapKey: any) {
    this.mapKey.next(mapKey);
  }

  public mapAddBtn: BehaviorSubject<any> = new BehaviorSubject(true);
  getMapAddBtn(): Observable<any> {
    return this.mapAddBtn.asObservable();
  }

  setMapAddBtn(mapAddBtn: any) {
    this.mapAddBtn.next(mapAddBtn);
  }

  public smsKey: BehaviorSubject<any> = new BehaviorSubject(false);
  getSmsKeyObs(): Observable<any> {
    return this.smsKey.asObservable();
  }

  setSmsKeyObs(smsKey: any) {
    this.smsKey.next(smsKey);
  }

  public smsAddBtn: BehaviorSubject<any> = new BehaviorSubject(true);
  getSmsAddBtn(): Observable<any> {
    return this.smsAddBtn.asObservable();
  }

  setSmsAddBtn(smsAddBtn: any) {
    this.smsAddBtn.next(smsAddBtn);
  }

  setSiteId(siteId: number) {
    this.siteSource.next(siteId);
  }

  public tableCreated: BehaviorSubject<any> = new BehaviorSubject(false);
  gettableCreatedObs(): Observable<any> {
    return this.tableCreated.asObservable();
  }

  settableCreatedObs(tableCreated: any) {
    this.tableCreated.next(tableCreated);
  }

  public subscriptionAudit: BehaviorSubject<any> = new BehaviorSubject(false);
  getsubscriptionAuditObs(): Observable<any> {
    return this.subscriptionAudit.asObservable();
  }

  setsubscriptionAuditObs(subscriptionAudit: any) {
    this.subscriptionAudit.next(subscriptionAudit);
  }

  public tableAuditView: BehaviorSubject<any> = new BehaviorSubject(false);
  gettableAuditViewObs(): Observable<any> {
    return this.tableAuditView.asObservable();
  }

  settableAuditViewObs(tableAuditView: any) {
    this.tableAuditView.next(tableAuditView);
  }

  public siteSelected: BehaviorSubject<any> = new BehaviorSubject('0');
  getsiteSelected(): Observable<any> {
    return this.siteSelected.asObservable();
  }

  setsiteSelected(siteSelected: any) {
    this.siteSelected.next(siteSelected);
  }

  public dashboardFromDate: BehaviorSubject<any> = new BehaviorSubject('0');
  getDashboardFromDate(): Observable<any> {
    return this.dashboardFromDate.asObservable();
  }

  setDashboardFromDate(dashboardFromDate: any) {
    this.dashboardFromDate.next(dashboardFromDate);
  }

  public dashboardToDate: BehaviorSubject<any> = new BehaviorSubject('0');
  getDashboardTODate(): Observable<any> {
    return this.dashboardToDate.asObservable();
  }

  setDashboardToDate(dashboardToDate: any) {
    this.dashboardToDate.next(dashboardToDate);
  }

  public siteSelectedNiaDashboard: BehaviorSubject<any> = new BehaviorSubject('0');
  getsiteSelectedNiaDashboard(): Observable<any> {
    return this.siteSelectedNiaDashboard.asObservable();
  }

  setsiteSelectedNiaDashboard(siteSelectedNiaDashboard: any) {
    this.siteSelectedNiaDashboard.next(siteSelectedNiaDashboard);
  }

  public dashboardFromDateNiaDashboard: BehaviorSubject<any> = new BehaviorSubject('0');
  getDashboardFromDateNiaDashboard(): Observable<any> {
    return this.dashboardFromDateNiaDashboard.asObservable();
  }

  setDashboardFromDateNiaDashboard(dashboardFromDateNiaDashboard: any) {
    this.dashboardFromDateNiaDashboard.next(dashboardFromDateNiaDashboard);
  }

  doPostWithResponseEmailLogin(url, data, successCallBack, errorCallBack) {
    url = this.addHostToUrl(url);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').append('Authentication-Type', AppConstants.Authentication_Type_Email);
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostWithResponseOtpLogin(url, data, successCallBack, errorCallBack) {
    url = this.addHostToUrl(url);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').append('Authentication-Type', AppConstants.Authentication_Type_Otp);
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetWithEmailHead(url: string, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.get(url, { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostWithEmailHead(url: string, data: any, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostWithEmailHead2(url: string, data: any, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.post(url, data, { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPutWithEmailPwdHead(url: any, data: any, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.put(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostWithResponseRenewJWT(url: any, data: any, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').append('Client_Code', clientCode);
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetRequest(url: string): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.get<any>(url);
  }

  doPostRequest(url: string, data: any): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.post<any>(url, data);
  }

  doPutRequest(url: string, data: any): Observable<any> {
    url = this.addHostToUrl(url);
    return this.http.put<any>(url, data);
  }

  doDeleteRequest(url: string): Observable<{}> {
    url = this.addHostToUrl(url);
    return this.http.delete(url);
  }

  doGetRequestWithResponse(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.get(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetRequestWithResponsePredictiveDashbaord(url: string, clientCode: string,  successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrlNia(url);
    this.http.get(url, { headers: this.headers.append('tenant_id', clientCode ), observe: 'response'}).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }


  doGetRequestWithResponsePredictiveDashbaordExcel(url: string, clientCode: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrlNia(url);
    this.http.get(url, { headers: this.headers.append('tenant_id', clientCode), observe: 'response', responseType: 'arraybuffer'}).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostRequestWithResponse(url: string, data: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.post(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostRequestWithResponseForLiveTracking(url: string, data: any, successCallBack: any, errorCallBack: any) {
    url = this.addLiveTrackingHostToUrl(url);
    this.http.post(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostRequestWithResponseForLiveTrack(url: string, data: any, successCallBack: any, errorCallBack: any) {
    // url = this.addHostToUrl(url);
    this.http.post(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPutRequestWithResponse(url: string, data: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.put(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doDeleteRequestWithResponse(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.delete(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostFile(url: string, data: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.post(url, data).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetWithBase64(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.get(url, { headers: this.headers, observe: 'response', responseType: 'text' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetWithPdf(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.get(url, { headers: { 'Content-Type': 'application/pdf', 'Accept': 'application/pdf' }, responseType: 'blob' as 'json' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doPostForJobs(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.post(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  doGetForOsm(url, successCallBack, errorCallBack) {
    this.http.get(url).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

  // SSO Login API
  doGetSsoLogin(url: string, clientCode: any, successCallBack: any, errorCallBack: any) {
    url = this.addHostToUrl(url);
    this.http.get(url, { headers: { 'Content-Type': 'application/json', 'Client_Code': clientCode }, observe: 'response' }).subscribe(
      {
        next: (response) => {
          successCallBack(response);
        },
        error: (error) => {
          errorCallBack(error);
        },

      });
  }

  // For Billing
  doPostRequestWithResponseForBilling(url: string, data: any, successCallBack: any, errorCallBack: any) {
    url = this.addBillingHostToUrl(url);
    this.http.post(url, JSON.stringify(data), { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

 // For reason
  doGetExceptionReason(url: string, successCallBack: any, errorCallBack: any) {
    url = this.addBillingHostToUrl(url);
    this.http.get(url, { headers: this.headers, observe: 'response' }).subscribe(response => {
      successCallBack(response);
    }, error => {
      errorCallBack(error);
    });
  }

}
