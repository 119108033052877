export const environment = {
  production: false,
  captcha: "6LfQpE8UAAAAAFzsUhEJv7ef373o-Gat0F2P5Qhe",
  baseUrl: 'https://atomuat.neoffice.app/atom/',
  baseUrlNia: 'https://neatest.neoffice.app/',
  baseUrlBilling: 'https://transportbillinguat.neoffice.app/atombilling/',
  // baseUrl: 'http://localhost:8080/atom/',
  liveTrakingBaseURL:"https://uat.myatom.app/livetrack/api/vehicle/",
  // baseTemplateDownloadUrl: 'https://atomuat.neoffice.app/atom/assets/excel/',
  baseTemplateDownloadUrl: 'https://transportuat.neoffice.app/assets/excel/',
  ssoLogIn: 'https://ssouat.neoffice.app/auth/realms/{clientCode}/protocol/openid-connect/auth?client_id={clientCode}&redirect_uri=https://transportuat.neoffice.app/?client={clientCode}&response_type=code&response_mode=fragment',
  // visaSsoLogIn: 'https://ssouat.neoffice.app/auth/realms/{clientCode}/protocol/openid-connect/auth?client_id={clientCode}&redirect_uri=https://visa.neoffice.app/?client={clientCode}&response_type=code&response_mode=fragment',
  ssoLogOut: 'https://ssouat.neoffice.app/auth/realms/{clientCode}/protocol/openid-connect/logout?client_id={clientCode}&redirect_uri=https://transportuat.neoffice.app',
  firebase: {
    apiKey: "AIzaSyDA1VaWhADwRbaET-yYmlyR8fFV9igLvAQ",
    authDomain: "atom-ebb83.firebaseapp.com",
    projectId: "atom-ebb83",
    storageBucket: "atom-ebb83.appspot.com",
    messagingSenderId: "144912422997",
    appId: "1:144912422997:web:c936b781009eba664d5cdc",
    measurementId: "G-CC28QDKR1Y",
    vapidKey:"BGzZbz5gQfu6H3oZROlwUzGzTvzhsV2XWfmo0cEDyt4ZJ9rk7xNHZAdzM4aTl3tCnfcPdLs1zvIHaG8wyZzvebA"
  }
};