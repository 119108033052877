<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId"
                            class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>Trips</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="float-end">
                                                <button class="btn btn-vehile me-3" style="border: none !important;"
                                                    (click)="onVehicleTypeCount()" *ngIf="vechileTypeCount.length > 0">
                                                    Vehicle Type Count
                                                </button>
                                                <button class="btn btn-btn-grey me-3" (click)="onClickBack()">
                                                    Cancel
                                                </button>
                                                <button class="btn red-btn-solid" (click)="allocateVendor()">
                                                    Submit
                                                </button>
                                            </div>
                                            <div class="d-flex">
                                                <span class="text-menu me-1" (click)="onClickBack()"
                                                    style="cursor: pointer; margin-left: -13px;"> <em
                                                        class="mdi mdi-arrow-left me-2"></em>Trips</span>
                                                <span class="text-submenu">/ Vendor Allocation</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="allocateVendorRatio">
                                        <app-allocate-vendor-ratio [siteid]="siteid" [shiftid]="shiftid"
                                            [tripdate]="date"></app-allocate-vendor-ratio>
                                    </div>

                                    <div class="row mt-3 gx-5" *ngIf="allocateVendorDndShow">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <span class="text-header">Trips</span>
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <mat-icon
                                                                style="font-size: 18px; font-weight: 400; color: #041D41; margin-top: 7px; cursor: pointer;"
                                                                (click)="reset()"
                                                                [ngClass]="{'disabled-refresh': _tripSheet.length === 0}"
                                                                title="Reset">refresh</mat-icon>
                                                            <button class="btn short-veh"
                                                                [disabled]="_tripSheet.length == 0"
                                                                (click)="shortVehicle()">Sort
                                                                by
                                                                Vehicle Types</button>
                                                            <button class="btn btn-moveall"
                                                                [disabled]="_tripSheet.length == 0" (click)="moveAll()">
                                                                Move All
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="vendor-list container-border p-2">
                                                    <div class="panel" dnd-sortable-container
                                                        [sortableData]="_tripSheet">
                                                        <ul class="list-unstyled">
                                                            <li *ngFor="let item of _tripSheet; let i = index"
                                                                class="list-group-item" dnd-sortable [dragData]="item"
                                                                [sortableIndex]="i">
                                                                <div class="row p-2 py-2">
                                                                    <div class="col-1">
                                                                        <img src="../../../../../assets/images/Vehicle.jpg"
                                                                            class="v-align">
                                                                    </div>

                                                                    <div class="col-10 aligntext">
                                                                        <span class="text-black">{{
                                                                            item.tripDetails.tripCode }},</span>&nbsp;
                                                                        <span style="color: #FF455B;">{{
                                                                            item.vehicleType.vehicleType
                                                                            }},</span>&nbsp;
                                                                        <span class="text-muted">{{
                                                                            item.tripDetails.tripUsers[0].landmark.area.area
                                                                            }},</span>&nbsp;
                                                                        <span class="text-muted">{{
                                                                            item.tripDetails.tripUsers[0].landmark.landmark
                                                                            }}</span>
                                                                    </div>

                                                                    <div class="col-1">
                                                                        <span (click)="addVendorAllocation(item)">
                                                                            <img src="../../../../../assets/images/plus.jpg"
                                                                                class="v-align">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 vendor">
                                            <div class="mt-2" *ngFor="let vendor of _vendorData; let i = index"
                                                style="overflow-y: auto;">
                                                <div class="mb-2" *ngIf="vendor.flag">
                                                    <span class="text-header">{{ vendor.name }}</span>
                                                    <span class="text-align me-3" style="float: right;">Trip count :
                                                        {{vendor.tripCount}}</span>
                                                </div>

                                                <div *ngIf="!(vendor.flag)" class="mb-2">
                                                    <span class="text-header">{{ vendor.name }}</span>
                                                    <span class="text-header" style="float: right; cursor: pointer;"
                                                        (click)="selectVendor(vendor)">Select</span>
                                                    <span class="text-align me-3" style="float: right;">Trip count :
                                                        {{vendor.tripCount}}</span>
                                                </div>

                                                <div class="box" dnd-sortable-container [sortableData]="vendor.trips">
                                                    <ul class="list-unstyled">
                                                        <li class="list-group-item"
                                                            *ngFor="let item of vendor.trips; let j = index"
                                                            dnd-sortable [sortableIndex]="j">
                                                            <div class="row p-2">
                                                                <div class="col-1">
                                                                    <img src="../../../../../assets/images/Vehicle.jpg"
                                                                        class="v-align">
                                                                </div>

                                                                <div class="col-10 aligntext">
                                                                    <span class="text-black">{{
                                                                        item.tripDetails.tripCode }} /</span>&nbsp;
                                                                    <span class="text-muted">{{
                                                                        item.vehicleType.vehicleType }} /</span>&nbsp;
                                                                    <span class="text-muted">{{
                                                                        item.tripDetails.tripUsers[0].landmark.area.area
                                                                        }}</span>
                                                                </div>

                                                                <span class="col-1"
                                                                    (click)="removeVendorAllocation(vendor,item)">
                                                                    <img src="../../../../../assets/images/icons/close icon.jpg"
                                                                        class="v-align">
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="p-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>