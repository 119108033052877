<div class="mat-modal-dialog panic-action-modal">
    <!-- <h2 mat-dialog-title>
        <span class="material-icons-outlined"
            style="color: #FF0000; position: relative; top: 1px; margin-right: 10px;">info</span>
        <span style="font-size: 14px; font-weight: 500; font-family: poppins; position: relative; top: 1px;">
            Panic Notification</span>
        <span class="material-icons-outlined" style="color:#747679; font-size: 25px; cursor: pointer;"
            (click)="dialogRef.close()">close</span>
    </h2> -->

    <h2 mat-dialog-title style="display: flex; align-items: center; justify-content: center;">
        <span class="material-icons-outlined"
            style="color: #FF0000; margin-right: 10px; position: relative; top: 1px; font-size: 20px;">
            info
        </span>
        <span
            style="color: #495057; font-size: 14px; font-weight: 500; font-family: Poppins; position: relative; top: 1px;">
            Panic Notification
        </span>
    </h2>

    <div class="expansion-panel">
        <mat-accordion *ngFor="let trip of tripListData;let t=index;">
            <mat-expansion-panel [expanded]="true" class="file-error-panel">
                <mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-sm-12 col-md-12" style="display: flex; flex-wrap: wrap; gap: 215px;">
                            <div style="font-size: 14px; font-family: 'Poppins'; font-weight: 500;">TripCode :
                                {{trip.tripDetails.tripCode}}</div>
                            <div style="font-size: 14px; font-family: 'Poppins'; font-weight: 500;">Activated By :
                                {{panicActionData[0].activatedBy}} ({{activatedEmpId}})</div>
                            <div style="font-size: 14px; font-family: 'Poppins'; font-weight: 500;">ActionTime :
                                {{panicActionData[0].actionTime}}</div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-9 col-md-9" style="display: flex; gap: 113px;">
                        <div style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                            Vendor Name: {{trip.vendorMaster?.name}} <br><br>
                            Vehicle Type: {{trip.vehicleType?.vehicleType}} <br><br>
                            Vehicle No: {{trip.vehicle?.registrationNo}}
                        </div>
                        <div style="width: 48%; position: relative; left: 195px;">
                            <label>Reason</label>
                            <input type="text" class="form-input" placeholder="Enter Reason" [(ngModel)]="reason"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3" style="display: flex; gap: 20px;">
                        <div style="padding-top: 1.5rem;">
                            <button class="btn red-btn-solid" (click)="updatePanicAction(trip)"
                                [disabled]="!reason">Submit</button>
                        </div>
                        <div style="padding-top: 1.5rem;">
                            <button class="btn black-btn-solid" (click)="onClickCancel()">Cancel</button>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-md-7" style="font-size: 14px; font-family: 'Poppins'; font-weight: 500;">
                        Vendor Name : {{trip.vendorMaster?.name}}
                        Vehicle Type : {{trip.vehicleType?.vehicleType}}
                        Vehicle No : {{trip.vehicle?.registrationNo}}
                    </div>
                </div> -->

                <!-- <div class="row">
                    <div class="col-sm-4 col-md-4" style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                        Vendor Name : {{trip.vendorMaster?.name}} &nbsp;&nbsp;&nbsp;&nbsp;
                        Vehicle Type : {{trip.vehicleType?.vehicleType}} <br><br>
                        Vehicle No : {{trip.vehicle?.registrationNo}}
                    </div>
                    <div class="col-sm-1 col-md-1"></div>
                    <div class="col-sm-7 col-md-7">
                        <div class="panic-act-flexbox">
                            <div style="width: 100%;">
                                <label>Reason</label>
                                <input type="text" class="form-control" placeholder="Enter Reason" [(ngModel)]="reason"
                                    autocomplete="off">
                            </div>

                            <div class="panic-width" style="padding-top: 1.5rem;">
                                <button class="btn red-btn-solid" (click)="updatePanicAction(trip)"
                                    [disabled]="!reason">Submit</button>
                            </div>
                            <div class="panic-width" style="padding-top: 1.5rem;">
                                <button class="btn black-btn-solid" (click)="onClickCancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <mat-dialog-content>
                    <div class="row m-0">
                        <div class="col-12 p-0">
                            <div class="row mt-3 p-0">
                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="card m-0 customviewCard-panic">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12 d-flex detail-div">
                                                    <div class="driver-detail-div-panic">
                                                        <div class="driver-detail-heading-panic">Driver Details
                                                        </div>

                                                        <div class="driver-detail-body-panic pe-1 mb-2">
                                                            <span
                                                                class="material-icons-outlined">directions_car_filled</span>
                                                            <span>{{ trip.driver?.name ? trip.driver?.name :
                                                                'Not
                                                                assigned' }}</span>
                                                        </div>

                                                        <div class="driver-detail-body-panic pe-1">
                                                            <!-- <span class="material-icons-outlined">call</span> -->
                                                            <button class="btn sos-ivrcall"
                                                                (click)="doIVRCall(trip.driver.id, trip.tripDetails.tripId, 'driver')"
                                                                [disabled]="!ivrSetupFlag || !trip.driver?.contactNumber">
                                                                <span
                                                                    style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                                            <span
                                                                style="position: relative; left: 5px;">{{trip.driver?.contactNumber
                                                                ?
                                                                trip.driver?.contactNumber : 'Not
                                                                assigned'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="driver-detail-div-panic">
                                                        <div class="driver-detail-heading-panic">Escort Details
                                                        </div>

                                                        <div class="driver-detail-body-panic pe-1 mb-2">
                                                            <span class="material-icons-outlined">person</span>
                                                            <span>{{trip.escort?.name ? trip.escort?.name : 'Not
                                                                assigned'}}</span>
                                                        </div>

                                                        <div class="driver-detail-body-panic pe-1">
                                                            <!-- <span class="material-icons-outlined">call</span> -->
                                                            <button class="btn sos-ivrcall"
                                                                (click)="doIVRCall(trip.escort.escortId, trip.tripDetails.tripId, 'escort')"
                                                                [disabled]="!ivrSetupFlag || !trip.escort?.contactNumber">
                                                                <span
                                                                    style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                                            <span style="position: relative; left: 5px;">{{
                                                                trip.escort?.contactNumber ?
                                                                trip.escort?.contactNumber : 'Not assigned'
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="driver-detail-div-panic"
                                                        *ngIf="trip.tripDetails.tripCode.indexOf('SPL') !== -1">
                                                        Special
                                                        Booking Trip</div>
                                                    <div class="driver-detail-div-panic"
                                                        *ngIf="trip.tripDetails.tripCode.indexOf('SPL') == -1">
                                                        <table class="table customTablepanic">
                                                            <thead>
                                                                <tr>
                                                                    <th>Total</th>
                                                                    <th>{{employeeStats[t]?.totalEmployees}}E
                                                                    </th>
                                                                    <th>{{employeeStats[t]?.maleShowStats +
                                                                        employeeStats[t]?.femaleShowStats}}S
                                                                    </th>
                                                                    <th>{{employeeStats[t]?.maleNoShowStats +
                                                                        employeeStats[t]?.femaleNoShowStats}}NS
                                                                    </th>
                                                                    <th>0C</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <td><img style="width: 15px;"
                                                                            src="./assets/images/livetracking/male.png">
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.maleEmployees}}E
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.maleShowStats}}S
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.maleNoShowStats}}NS
                                                                    </td>
                                                                    <td>0C</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><img style="width: 15px;"
                                                                            src="./assets/images/livetracking/female.png">
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.femaleEmployees}}E
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.femaleShowStats}}S
                                                                    </td>
                                                                    <td>{{employeeStats[t]?.femaleNoShowStats}}NS
                                                                    </td>
                                                                    <td>0C</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <section id="conference-timeline">
                                        <div class="conference-center-line"></div>
                                        <div class="conference-timeline-content">
                                            <div class="timeline-article">
                                                <div class="content-right-container d-flex">
                                                    <div class="start">{{trip.startDateTime ?
                                                        trip.startDateTime?.split(' ')[1].slice(0,5) : 'NA'}}
                                                    </div>
                                                    <div class="start">- Start Time </div>
                                                </div>
                                                <div class="meta-date start_end_time"
                                                    [style.background]="(employeeStats[t]?.maleShowStats+employeeStats[t]?.femaleShowStats > 0) ? '#43DF94' : '#e4e4e4'">
                                                </div>
                                            </div>
                                            <div class="timeline-article"
                                                *ngFor="let user of trip.tripDetails.tripUsers; let u=index;">
                                                <div class="content-left-container d-flex" style="align-items: center;">
                                                    <div class="col-md-2"><img style="width: 15px;"
                                                            [src]="user.tripUser.userInfoDetails.gender=='Female' ?
                                                        'assets/images/livetracking/female.png' : 'assets/images/livetracking/male.png'"></div>
                                                    <div class="col-md-8"
                                                        style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                                        {{user.tripUser.userInfoDetails.employeeId}} -
                                                        {{user.tripUser.userInfoDetails.displayName |
                                                        slice:0:13}}</div>

                                                    <div class="col-md-2"
                                                        style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                                        {{user.pickupDropTime
                                                        ? (user.pickupDropTime | slice:0:5) : 'NA'}}</div>
                                                </div>
                                                <div class="content-right-container d-flex"
                                                    style="align-items: center;">
                                                    <div class="col-md-8"
                                                        style="font-size: 13px; font-family: 'Poppins'; font-weight: 400;">
                                                        <span *ngIf="user.showStatus!='SHOW'">{{user.showStatus}}</span>
                                                        <span *ngIf="user.showStatus=='SHOW'">IN
                                                            {{user.boardedDateTime?.split(' ')[1] | slice:0:5}}
                                                            - OUT
                                                            <span>{{user.arrivalDateTime ?
                                                                (user.arrivalDateTime?.split(' ')[1] |
                                                                slice:0:5) :
                                                                'NA'}}</span> </span>
                                                    </div>
                                                    <div class="col-md-2"><img style="width: 15px;"
                                                            [src]="user.showStatus=='SHOW' ? 'assets/images/Group.png' : 'assets/images/livetracking/into_mark.png'">
                                                    </div>

                                                    <div class="col-md-1">
                                                        <!-- <span class="material-icons-outlined ivrCall"
                                                            matTooltip="Make a call"
                                                            [ngClass]="{'call-disabled': user.arrivalDateTime !== null || !ivrSetupFlag}"
                                                            (click)="doIVRCall(user.tripUser.id, trip.tripDetails.tripId, 'employee')">contact_phone</span> -->
                                                        <button class="btn sos-ivrcall"
                                                            (click)="doIVRCall(user.tripUser.id, trip.tripDetails.tripId, 'employee')"
                                                            [disabled]="!ivrSetupFlag || user.arrivalDateTime !== null">
                                                            <span
                                                                style="font-size: 13px; font-weight: 500; font-family: 'poppins' !important;">Call</span></button>
                                                    </div>
                                                </div>
                                                <div class="meta-date"
                                                    [style.background]="(user.showStatus=='SHOW') ? '#43DF94' : '#e4e4e4'">
                                                </div>
                                            </div>

                                            <div class="timeline-article">
                                                <div class="content-right-container d-flex"
                                                    style="align-items: center;">
                                                    <div class="col-md-2 start">{{trip.stopDateTime ?
                                                        (trip.stopDateTime?.split(' ')[1] | slice:0:5) : 'NA'}}
                                                    </div>
                                                    <div class="start">- End Time </div>
                                                </div>
                                                <img style="width: 15px;" class="meta-date1 start_end_time"
                                                    src="./assets/images/loc.svg">
                                            </div>
                                        </div>
                                    </section>

                                    <div class="row mt-5" *ngIf="trip.tripDetails.tripCode.indexOf('SPL') == -1">
                                        <div class="col-sm-4 col-md-4">
                                            <div class="v_lable">Estimated</div>
                                            <div class="v_detail">{{trip?.etaMin}} min/<span>{{trip?.etaKm |
                                                    number :
                                                    '1.2-2'}} Km</span> </div>
                                        </div>

                                        <div class="col-sm-4 col-md-4">
                                            <div class="v_lable">Consumed</div>
                                            <div class="v_detail">{{trip?.consumedMin}}
                                                min/<span>{{trip?.consumedKm |
                                                    number : '1.2-2'}} Km</span></div>
                                        </div>

                                        <div class="col-sm-4 col-md-4">
                                            <div class="v_lable">Late</div>
                                            <div class="v_detail">{{trip?.lateTime}} min<span></span> </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ps-0">
                                    <div class="panicMap" [id]="'panicTripMap'+trip.tripDetails.tripId"></div>
                                    <button class="btn nearbyVeh"
                                        style="position: relative; left: 5px; bottom: 170px; z-index: 1000;"
                                        (click)="showNearByVehicle()">Near by vehicles</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-dialog-content>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>