import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-vendor-tracktrips-rellocation',
  templateUrl: './vendor-tracktrips-rellocation.component.html',
  styleUrls: ['./vendor-tracktrips-rellocation.component.scss']
})
export class VendorTracktripsRellocationComponent {

  public _mFormGroup: FormGroup;
  public mVehicle = new FormControl('', Validators.required);
  public mDriver = new FormControl('', Validators.required);
  public reason = new FormControl('', Validators.required);

  reasonData: any[] = [];
  vehicleListData: any[] = [];
  driverData: any[] = [];
  tempDriverData: any[] = [];
  vehicleId: any = '';
  driverId: any = '';

  constructor(public dialogRef: MatDialogRef<VendorTracktripsRellocationComponent>,
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._mFormGroup = this.fb.group({
      'mVehicle': this.mVehicle,
      'mDriver': this.mDriver,
      'reason': this.reason,
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.vehicleListData = this.data.vehicleData;
    }, 1000);
    this.driverData = [];
    this.tempDriverData = [];
  }

  vehicleSearchSubmit(registrationNo) {
    this.vehicleId = '';
    this.driverId = '';
    this.driverData = [];
    this.tempDriverData = [];
    this.mDriver.setValue('');
    const vehFullObj = this.vehicleListData.find(ele => ele.registrationNo == registrationNo);
    if (vehFullObj) {
      this.vehicleId = vehFullObj.id;
      this.driverData = vehFullObj.drivers;
      this.tempDriverData = vehFullObj.drivers;
    }
  }

  omit_special_char_And_String(event) {
    var k;
    k = event.charCode;  //k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 32 || (k >= 48 && k <= 57));
  }

  searchFilter(event) {
    this.vehicleListData = this.data.vehicleData.filter(ele => ele.registrationNo.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  driverFilter(event) {
    this.driverData = this.tempDriverData.filter(data => data.name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  driverSearchSubmit(driver) {
    this.driverId = driver.id;
  }

  getReallocationReasons() {
    this.apiService.doGetRequestWithResponse(ApiConstants.vendorReallocationReason,
      success => {
        if (success.status == 200) {
          this.reasonData = success.body;
        } else {
          this.reasonData = [];
          this.notifyService.showInfo("No reasons found", null);
        }
      }, (error) => {
      })
  }

  onConfirmation() {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.vendorConfirmReallocation + this.data.tripId
      + '&driverId=' + this.driverId + '&vehicleId=' + this.vehicleId + '&reason=' + this.reason.value, null, (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
          if (success.body.message == 'Updated successfully') {
            this.notifyService.showSuccess(success.body.message, null);
            this.dialogRef.close(success.body.message);
          } else {
            this.ngxLoader.stop();
            this.notifyService.showWarning(success.body.message, null);
          }
        } else {
          this.ngxLoader.stop();
          this.notifyService.showWarning("Failed to reallocate", null);
        }
      }, (error) => {
        this.ngxLoader.stop();
        this.notifyService.showError("Error while doing reallocation", null);
        console.log(error);
      })
  }

}
