<div class="mat-modal-dialog vendor-adj-modal">
    <h2 mat-dialog-title>
        <span class="vendor-adj-mtitle-prop">Adjustment Trips</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 35px; float: right; position: relative; top: 2px; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </h2>
    <mat-dialog-content>
        <form [formGroup]="_mFormGroup">
            <div class="row">
                <div class="col-sm-12">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Search Employee</mat-label>
                                <input style="font-size: 12px;" type="text" placeholder="Search Employee" matInput
                                    formControlName="userName" [matAutocomplete]="auto"
                                    (input)="searchSubject$.next($event.target.value)">
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-bottom: 5px; padding-right: 5px;">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of user" [value]="option.userInfoDetails.displayName"
                                        (onSelectionChange)="searchSubmit(option)">
                                        {{option.userInfoDetails.employeeId}}-{{option.userInfoDetails.displayName}}</mat-option>
                                </mat-autocomplete>
                                <mat-hint style="white-space: nowrap;">Search key >= 3 characters.</mat-hint>
                                <mat-progress-bar style="top:6px" class="example-margin" mode="indeterminate"
                                    *ngIf="empSearchFlag">
                                </mat-progress-bar>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>EmployeeId</mat-label>
                                <input matInput readonly placeholder="Employee id" type="text"
                                    formControlName="employeeId">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Site</mat-label>
                                <mat-select placeholder="Sites" formControlName="site" required
                                    (selectionChange)="onSiteChange($event)">
                                    <mat-option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                        {{ site.siteName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mSites.hasError('required')">
                                    Please select site
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4  col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Vendor Master</mat-label>
                                <mat-select placeholder="Vendor Master" formControlName="vendor" required>
                                    <mat-option *ngFor="let vendor of _mVendors" [value]="vendor.id">{{vendor.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mVendor.hasError('required')">
                                    Please select vendor master
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Log type</mat-label>
                                <mat-select placeholder="Log type" formControlName="logType" required>
                                    <mat-option *ngFor="let type of logType" [value]="type.key">
                                        {{ type.value }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mLogType.hasError('required')">
                                    Please select log type
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex">
                                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="escortRequired"
                                    (click)="onEscortToggle()"></ui-switch>
                                <span
                                    style="position: relative; right: -19px; top: 4px; font-size: 14px; font-weight: 400; font-family: 'Poppins';">Escort
                                    required</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Escort</mat-label>
                                <mat-select placeholder="Escort" formControlName="escort" required>
                                    <mat-option *ngFor="let escort of _mEscort" [value]="escort.id">
                                        {{ escort.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mEscort.hasError('required')">
                                    Please select escort
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Vehicle Type</mat-label>
                                <mat-select placeholder="Vehicle type" formControlName="vehicleType"
                                    (selectionChange)="onVehicleTypeChange()" required>
                                    <mat-option *ngFor="let type of _mVehicleTypes" [value]="type.vehicleTypeId">
                                        {{ type.vehicleType }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mVehicleType.hasError('required')">
                                    Please select vehicle type
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Travel Date</mat-label>
                                <input matInput [min]="toDatePickerMin" (click)="picker1.open()"
                                    (dateChange)="dateChanged()" [matDatepicker]="picker1" placeholder="Travel date"
                                    required formControlName="travelDate" readonly="readonly">
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                    <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Search Vehicle</mat-label>
                                <input style="font-size: 12px;" type="text" placeholder="Search vehicle" matInput
                                    formControlName="vehicleNumber" [matAutocomplete]="vauto"
                                    (input)="vehicleSearchSubject$.next($event.target.value)">
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-bottom: 5px; padding-right: 5px">
                                <mat-autocomplete #vauto="matAutocomplete">
                                    <mat-option *ngFor="let type of mVehicleList" [value]="type.id"
                                        (onSelectionChange)="onVehicleChanges(type)" required>
                                        {{ type.registrationNo }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint style="white-space: nowrap;">Search key >= 3 characters.</mat-hint>
                                <mat-progress-bar style="top:6px" class="example-margin" mode="indeterminate"
                                    *ngIf="vehicleSearchFlag">
                                </mat-progress-bar>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Driver Name</mat-label>
                                <mat-select placeholder="driver name" formControlName="driverName" required>
                                    <mat-option *ngFor="let type of _driverList" [value]="type.id">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mDriverName.hasError('required')">
                                    Please select driver Name
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Nearest ShiftTime</mat-label>
                                <mat-select placeholder="Nearest ShiftTime" formControlName="nearestShiftTime" required>
                                    <mat-option *ngFor="let time of reportingTimes" [value]="time.value">
                                        {{ time.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                                    *ngIf="mNearestShiftTime.hasError('required')">
                                    Please select nearest shifttime
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size-vendor filed-ven"
                                appearance="outline">
                                <mat-label>Approx Km</mat-label>
                                <input matInput placeholder="Approx km" type="text" formControlName="approxKm"
                                    (keypress)="omit_number($event)">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <mat-form-field class="example-full-width text-field-size-for-reason-ven filed-ven"
                                appearance="outline">
                                <mat-label>Reason</mat-label>
                                <textarea matInput placeholder="Reason" type="text" formControlName="reason"
                                    style="height: 25px;" class="vendor-adj-max-height"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex" style="float: right;">
                <div class="col-sm-12">
                    <button (click)="onClickCancel()" class="btn footerBtn secondary-btn-solid me-3">
                        Cancel
                    </button>
                    <button class="btn footerBtn red-btn-solid" (click)="onSubmit()">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>