import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsComponent } from './layouts.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { ConfirmationAlertComponent } from './confirmation-alert/confirmation-alert.component';
import { MaterialModule } from '../material.module';
import { PanicActionNotificationComponent } from './panic-action-notification/panic-action-notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ContextSearchComponent } from './context-search/context-search/context-search.component';
import { SearchDialogBoxComponent } from './context-search/context-search/search-dialog-box/search-dialog-box/search-dialog-box.component'
import { BasicTableComponent } from '../shared/basic-table/basic-table.component';
import { SharedModule } from '../shared/shared.module';
import { NearbyVehTripInfoComponent } from './nearby-veh-trip-info/nearby-veh-trip-info.component';


@NgModule({
  declarations: [
    LayoutsComponent,
    TopbarComponent,
    ConfirmationAlertComponent,
    PanicActionNotificationComponent,
    ContextSearchComponent,
    SearchDialogBoxComponent,
    NearbyVehTripInfoComponent,
  
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    NgbDropdownModule,
    ClickOutsideModule,
    SimplebarAngularModule,
    SharedModule
  ],
  
})
export class LayoutsModule { }
