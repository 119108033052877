<div class="mat-modal-dialog vendor-realloc-modal">
    <h2 mat-dialog-title></h2>
    <mat-dialog-content style="position: relative; bottom: 40px;">
        <span class="material-icons-outlined"
            style="color: #FF0000; font-size: 19px; position: relative; top: 5px; margin-right: 10px;">
            info</span> <span style="color: #FF0000; font-size: 14px; font-weight: 500; font-family: poppins;">Track
            Trips Reallocation.</span>
    </mat-dialog-content>

    <mat-dialog-content style="position: relative; bottom: 40px;">
        <form [formGroup]="_mFormGroup">
            <div class="row">
                <div class="col-sm-4 col-md-4">
                    <label class="font-size-font">Vehicle<sup class="mandatory">*</sup></label>
                    <mat-form-field class="vehallocSerch" appearance="outline">
                        <input type="text" placeholder="Vehicle Number" matInput formControlName="mVehicle"
                            (keypress)="omit_special_char_And_String($event)" [matAutocomplete]="vehicleNoAuto"
                            maxlength="12" (input)="searchFilter($event)" required>
                        <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                        <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                            (optionSelected)="vehicleSearchSubmit($event.option.value)">
                            <mat-option class="vehallocOption" *ngFor="let option of vehicleListData"
                                [value]="option.registrationNo">
                                {{ option.registrationNo }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-sm-4 col-md-4">
                    <label class="font-size-font">Driver<sup class="mandatory">*</sup></label>
                    <mat-form-field class="vehallocSerch" appearance="outline">
                        <input type="text" placeholder="Select Driver" matInput formControlName="mDriver"
                            [matAutocomplete]="driverName" maxlength="30" (input)="driverFilter($event)" required>
                        <img matSuffix src="assets/images/search.svg" height="27"
                            style="margin-top: -22px;padding-right: 5px;">
                        <mat-autocomplete #driverName="matAutocomplete" (optionSelected)="getReallocationReasons()">
                            <mat-option class="vehallocOption" *ngFor="let data of driverData" [value]="data.name"
                                (onSelectionChange)="driverSearchSubmit(data)">{{
                                data.name }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-sm-4 col-md-4">
                    <label class="font-size-font">Reason<sup class="mandatory">*</sup></label>
                    <select class="form-select" formControlName="reason">
                        <option value="" disabled>Select Reasons</option>
                        <option *ngFor="let data of reasonData" [value]="data.reason">
                            {{ data.reason }}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions align="end" style="position: relative; right: 15px;">
        <button type="button" class="btn footerBtn cancel-alloc me-3" (click)="this.dialogRef.close()">Cancel</button>
        <button type="button" class="btn footerBtn submit-alloc" (click)="onConfirmation()"
            [disabled]="_mFormGroup.invalid">Submit</button>
    </div>
</div>